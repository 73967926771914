import React from 'react'
import { actions } from './store'
import style from './login.module.scss'
import { firebase, auth } from '../../firebase'
import Svg from '../svg'

const Options = () => {
  const providers = ['google', 'facebook', 'apple', 'microsoft', 'yahoo']
  const handleClick = (provider: string) => {
    let authProvider: firebase.auth.AuthProvider
    switch (provider) {
      case 'google': {
        authProvider = new firebase.auth.GoogleAuthProvider()
        break
      }
      case 'facebook': {
        authProvider = new firebase.auth.FacebookAuthProvider()
        break
      }
      case 'apple': {
        authProvider = new firebase.auth.OAuthProvider('apple.com')
        break
      }
      case 'microsoft': {
        authProvider = new firebase.auth.OAuthProvider('microsoft.com')
        break
      }
      case 'yahoo': {
        authProvider = new firebase.auth.OAuthProvider('yahoo.com')
        break
      }
    }
    actions.setLoading(true)
    auth
      .signInWithPopup(authProvider!)
      .then(() => {
        actions.setLoading(false)
        actions.setShowLogin(false)
      })
      .catch((err) => {
        console.log(err)
        actions.setLoading(false)
      })
  }
  return (
    <div className={style.login__options}>
      {providers.map((el) => (
        <button key={el} onClick={() => handleClick(el)}>
          <Svg path={`/${el}.svg`} />
        </button>
      ))}
    </div>
  )
}

export default Options
