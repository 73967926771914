import React, { useEffect, useState } from 'react'
import Lottie, { LottieProps } from 'react-lottie'
import axios from 'axios'
import { staticFile } from '../../helpers'
import { useToggle } from '../../hooks'

interface Props
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'> {
  size?: string
  src?: string
}

const Loading: React.FC<Props> = ({ size = '100%', src = staticFile('loading.json'), ...props }) => {
  const [flag, _, setToggle] = useToggle(true)
  const [source, setSource] = useState<Object | null>(null)
  useEffect(() => {
    setToggle(true)
    axios(src)
      .then(({ data }) => {
        setSource(data)
        setToggle(false)
      })
      .catch((err) => console.error(err))
  }, [])

  const lottieProps: LottieProps = {
    options: {
      loop: true,
      animationData: source,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    },
    height: size,
    width: size,
    isClickToPauseDisabled: true,
  }

  return (
    <div
      {...props}
      css={`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.6);
      `}
    >
      {flag ? 'Loading...' : <Lottie {...lottieProps} />}
    </div>
  )
}

export default Loading
