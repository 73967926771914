import React, { useState } from 'react'
import { useToggle } from '../../hooks'
import { firebase } from '../../firebase'
import { Head } from './Header'
import { redeemActions, useRedeemState } from './redeem-state'
import style from './style.module.scss'
import Loading from '../../components/loading'
import { actions } from '../../store/user'
import ActivePromocode from './ActivePromocode'

const Promocode = () => {
  const promocode = useRedeemState((state) => state.promocode)
  const [loading, toggleLoading] = useToggle(false)
  const [error, setError] = useState('')
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    toggleLoading()
    setError('')
    const data = await firebase.functions().httpsCallable('PROMOCODE_activate')({
      promocode,
      course_id: -1,
    })
    console.log(data)
    if (data?.data?.error) {
      switch (data.data.error.code) {
        case 429: {
          setError('Invalid code. You have no more attempts left for today. Please try again tomorrow.')
          break
        }
        default: {
          setError(`Invalid code. You have ${10 - data.data.error.data.count} attempts left.`)
        }
      }
      toggleLoading()
    } else {
      switch (data.data.commands.name) {
        case 'all_in_one_lifetime_all': {
          redeemActions.setSelected(0, 0)
          redeemActions.setPosition('finish')
          break
        }
        case 'all_in_one_lifetime_course': {
          redeemActions.setSelectedPeriod(0)
          redeemActions.setPosition('select')
          break
        }
      }
      toggleLoading()
    }
    //
  }
  return (
    <div className={style.promocode}>
      <Head />
      <section>
        <div className="container">
          <h3 className="text lg medium center">Please enter your promo code below</h3>
          <form className="d-flex flex-column align-items-center" onSubmit={onSubmit}>
            <input
              className="text md mb-5"
              type="text"
              placeholder="promo code"
              value={promocode}
              onChange={(e) => redeemActions.setPromocode(e.target.value)}
            />
            <div className="text sm bold center mb-30" style={{ color: '#9A0000' }}>
              {error}
            </div>
            <div className={style.form__button}>
              <button className={['text md medium', loading ? style.hover : ''].join(' ')}>Submit</button>
              {loading ? <Loading size="80%" /> : ''}
            </div>
          </form>
        </div>
      </section>
      <ActivePromocode />
    </div>
  )
}

export default Promocode
