import React, { useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import shallow from 'zustand/shallow'
import style from './menu.module.scss'
import Item from './item'
import UserInfo from './user-info'
import { useStore, actions } from './store'
import CloseBtn from './close-btn'
import { useClickAway } from 'react-use'

const Menu = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { showMenu } = useStore((state) => state, shallow)
  const { fade } = useSpring({ fade: showMenu ? 1 : 0 })
  useClickAway(ref, () => {
    if (showMenu) {
      actions.toggleMenu()
    }
  })
  console.log(showMenu)
  return (
    <animated.div
      style={{
        transform: fade.interpolate((value) => `translateX(-${value * 100}%)`),
      }}
      className={style.menu}
      ref={ref}
    >
      <CloseBtn fade={fade} />
      <Item close onClick={actions.toggleMenu.bind(null, undefined)}>
        Close
      </Item>
      <UserInfo />
      <Item as="a" href="https://www.funeasylearn.com/">
        Home
      </Item>
      <Item as="a" href="https://www.funeasylearn.com/press">
        Press
      </Item>
      <Item as="a" href="https://www.funeasylearn.com/about">
        About us
      </Item>
      <Item as="a" href="https://www.funeasylearn.com/contact">
        Contact us
      </Item>
    </animated.div>
  )
}

export default Menu
