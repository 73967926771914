import React, { useState } from 'react'
import Svg from '../svg'
import style from './login.module.scss'
import { useToggle } from '../../hooks'

type Props = {
  className?: string
  type: 'email' | 'password' | 'digit'
  placeholder?: string
  onChange?: (text: string) => void
  value?: string
  error?: string | JSX.Element
}

const Input: React.FC<Props> = ({ type, placeholder, onChange, value, error, className }) => {
  const [state, setState] = useState('')
  const [showPass, toggleShowPass] = useToggle()
  const inputValue = value ? value : state
  const inputType = type === 'password' ? (showPass ? 'text' : 'password') : type === 'email' ? type : 'text'
  const inputSvg =
    type === 'email' ? '/email.svg' : type === 'password' ? '/password.svg' : type === 'digit' ? '/hashtag.svg' : ''
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setState(value)
    if (onChange) onChange(value)
  }

  const button = (
    <button type="button" onClick={toggleShowPass}>
      {showPass ? <Svg path="/showPass.svg" /> : <Svg path="/hidePass.svg" />}
    </button>
  )

  return (
    <div className={`${style.inputWrapper} ${className}`}>
      <div className={style.input}>
        <Svg path={inputSvg} />
        <input
          className="text sm"
          type={inputType}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
        />
        {type === 'password' && button}
      </div>

      {error && <div className={`text sm ${style.error}`}>{error}</div>}
    </div>
  )
}

export default Input
{
}
