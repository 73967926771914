import create from 'zustand'

type State = {
  showMenu: boolean
}

const initialState: State = {
  showMenu: false,
}

const useStore = create<State>(() => ({ ...initialState }))

export const actions = {
  toggleMenu(boolean?: boolean) {
    if (boolean) {
      useStore.setState({ showMenu: boolean })
    } else {
      useStore.setState((state) => ({ showMenu: !state.showMenu }))
    }
  },
}

export { useStore }
