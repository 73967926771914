import React from 'react'
import Svg from '../svg'
import { actions as userActions, useStore as userUseStore } from '../../store/user'
import { actions as loginActions } from '../../components/login/store'
import { useStore as menuStore } from '../../components/menu/store'
import { useSpring, animated } from 'react-spring'
import { useMedia } from '../../hooks/useMedia'

const Navbar = () => {
  const media = useMedia()
  const showMenu = menuStore((state) => state.showMenu)
  const fade = useSpring({ x: showMenu && media < 699 ? 1 : 0 })
  const fadeOption = useSpring({ x: showMenu ? 1 : 0 })
  const UID = userUseStore((state) => state.UID)
  return (
    <nav className="navbar">
      <animated.div
        style={{
          transform: fade.x.interpolate((x) => `translateX(calc(${x} * calc(-100% - 3rem)))`),
        }}
        className="navbar__left"
      >
        <a href="/">
          <Svg path="/navbar-logo.svg" className="navbar__logo" />
        </a>
      </animated.div>
      {UID ? (
        <animated.button
          style={{
            transform: fadeOption.x.interpolate((x) => `translateX(${x * 350 * -1}px)`),
          }}
          className="navbar__right text bold sm mr-60 d-none d-md-block"
          onClick={userActions.signOut}
        >
          Sign out
        </animated.button>
      ) : (
        <animated.button
          style={{
            transform: fadeOption.x.interpolate((x) => `translateX(${x * 350 * -1}px)`),
          }}
          className="navbar__right text bold sm mr-60 d-none d-md-block"
          onClick={loginActions.setShowLogin.bind(null, true)}
        >
          Sign in
        </animated.button>
      )}
    </nav>
  )
}

export default Navbar
