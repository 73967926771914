import styled from 'styled-components'

const Card = styled.div`
  background: #ffffff;
  border: 1px solid #cec6cf;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.284583);
  border-radius: 12px;
`

export default Card
