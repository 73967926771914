import { useLayoutEffect } from 'react'

export const useInjectClass = <T extends HTMLElement>(element: T | null, className: string) => {
  useLayoutEffect(() => {
    if (element) {
      element.classList.add(className)
    }
    return () => {
      if (element) {
        element.classList.remove(className)
      }
    }
  }, [element, className])
}
