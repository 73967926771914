import React from 'react'
import { OpaqueInterpolation } from 'react-spring'
import style from './menu.module.scss'
import { actions } from './store'
import { animated } from 'react-spring'

interface Props {
  fade: OpaqueInterpolation<number>
}

const CloseBtn: React.FC<Props> = ({ fade }) => {
  return (
    <animated.div
      style={{
        transform: fade.interpolate((x) => `translateX(${x * 10}px)`),
      }}
      className={style.menu__closeBtn}
      onClick={actions.toggleMenu.bind(null, undefined)}
    >
      <div className={style.wrapper}>
        <animated.span
          style={{
            width: fade.interpolate({ range: [0, 1], output: [26, 37] }).interpolate((x) => x + 'px'),
            transform: fade.interpolate((x) => `rotate(${x * 45}deg)`),
            top: fade.interpolate({ range: [0, 1], output: [3, -2] }).interpolate((x) => x + 'px'),
          }}
        ></animated.span>
        <animated.span
          style={{
            width: fade.interpolate({ range: [0, 1], output: [26, 40] }).interpolate((x) => x + 'px'),
            transform: fade.interpolate((x) => `translateX(${x * 24}px)`),
          }}
        >
          <span></span>
          <animated.span
            style={{
              width: fade.interpolate((x) => x * 16 + 'px'),
            }}
          ></animated.span>
        </animated.span>
        <animated.span
          style={{
            width: fade.interpolate({ range: [0, 1], output: [26, 37] }).interpolate((x) => x + 'px'),
            transform: fade.interpolate((x) => `rotate(${x * -45}deg)`),
            top: fade.interpolate({ range: [0, 1], output: [19, 24] }).interpolate((x) => x + 'px'),
          }}
        ></animated.span>
      </div>
    </animated.div>
  )
}

export default CloseBtn
