import React from 'react'
import useSWR from 'swr'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import { codeToName, period } from './helpers'
import { redeemActions, useRedeemState } from './redeem-state'
import { fetcher } from '../../services'
import Card from '../../components/card'
import style from './style.module.scss'

const ActivePromocode = () => {
  const { data } = useSWR('/redeem/activ-promocode', fetcher.getAllPromocodes)
  const preActivated = data?.filter((el) => el.course_id === -1)
  const activated = data?.filter((el) => el.course_id >= 0)
  // console.log(data)
  if (!preActivated?.length && !activated?.length) return null
  return (
    <section style={{ backgroundColor: '#FFD558' }}>
      <div className="container">
        <div className="row flex-column align-items-center justify-content-center">
          {preActivated && preActivated.length
            ? preActivated?.map((el) => (
                <div key={el.promocode} className="col-md-10 col-lg-8 col-xl-6 mb-30">
                  <Card className="p-15 d-flex flex-column align-items-center justify-content-center">
                    <div className="text md">
                      Redeemed code: <b>{el.promocode}</b>
                    </div>
                    <button
                      onClick={() => {
                        redeemActions.setPromocode(el.promocode)
                        redeemActions.setPosition('select')
                      }}
                      className="text md mt-30"
                      style={{ padding: '1rem 4rem' }}
                    >
                      Choose language
                    </button>
                  </Card>
                </div>
              ))
            : ''}
        </div>

        {activated && activated.length > 0 && <Tabel promocodes={activated} />}
      </div>
    </section>
  )
}
interface Promocode {
  period: number
  end_date: number
  start_date: number
  course_id: number
  promocode: string
}
const Tabel: React.FC<{ promocodes: Promocode[] }> = ({ promocodes }) => {
  return (
    <>
      <h3 className="text lg medium center mb-20">Activated promo codes</h3>
      <div className={`${style.table} d-md-block d-none`}>
        <div className="row justify-content-center">
          <div className="col col-xl-10">
            <div className={`${style.grid} text sm center mb-5`}>
              <div>Language</div>
              <div>Code</div>
              <div>Activated</div>
              <div>Period</div>
            </div>
            {promocodes.map((el, idx) => (
              <Card key={idx} className="mb-15">
                <div className={`${style.grid} ${style.grid__border} text sm center`}>
                  <div className="text md medium start py-15 pl-15 pl-lg-30">{codeToName(el.course_id)}</div>
                  <div>{el.promocode}</div>
                  <div>{dayjs(el.start_date).format('DD.MM.YYYY')}</div>
                  <div>{period(el.period)}</div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className={`${style.tableSm} d-md-none d-block`}>
        {promocodes.map((el, idx) => (
          <Card key={idx} className="mb-15">
            <div className={`text xs center`}>
              <div className="text md medium center py-10">{codeToName(el.course_id)}</div>
              <div style={{ background: '#F0EBF1' }}>
                <div className={[style.grid, 'py-10 px-5'].join(' ')}>
                  <div>Code</div>
                  <div>Activated</div>
                  <div>Period</div>
                </div>
              </div>
              <div className={[style.grid, style.grid__border, 'px-5 py-15'].join(' ')}>
                <div>{el.promocode}</div>
                <div>{dayjs(el.start_date).format('DD.MM.YYYY')}</div>
                <div>{period(el.period)}</div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default ActivePromocode
