import React, { useState } from 'react'
import style from './login.module.scss'
import Footer from './footer'
import Options from './options'
import { auth, firebase } from '../../firebase'
import { felService } from '../../services'
import { useStore, actions } from './store'
import Input from './input'
enum Providers {
  null,
  password,
  phone,
  google,
  facebook,
  twitter,
  github,
  apple,
  yahoo,
  microsoft,
}

const SingUp = () => {
  const store = useStore()
  const [repeatPass, setRepeatPass] = useState('')
  const [repeatPassError, setRepeatPassError] = useState('')

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    actions.setEmailError('')
    actions.setPasswordError('')
    setRepeatPassError('')

    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        store.email,
      )
    ) {
      actions.setEmailError('Wrong Email')
      return
    }
    if (store.password.length < 6) {
      actions.setPasswordError('Your password must be at least 6 characters long.')
      return
    }
    if (store.password !== repeatPass) {
      setRepeatPassError('The passwords do not match')
      return
    }
    actions.setLoading(true)
    const isError = await auth.signInWithEmailAndPassword(store.email, '_________________').catch(async (err) => {
      console.error(err)
      if (err.code === 'auth/wrong-password') {
        const { data } = await firebase.functions().httpsCallable('EMAIL_get_provider')({
          email: store.email,
        })
        if (data.data !== 1) {
          actions.setEmailError(
            <div>
              <span style={{ color: '#9A0000' }}>
                This email is already in use. Sign in with {Providers[data.data]}, to enter your existent account.
              </span>
            </div>,
          )
          actions.setLoading(false)
          return true
        }

        actions.setEmailError(
          <div>
            <span style={{ color: '#9A0000', display: 'inline' }}>
              This email is already in use. Sign in with your existent account.&nbsp;
            </span>
            <span style={{ display: 'inline' }}>
              <button
                className="text sm bold link"
                onClick={() => {
                  actions.setPosition('create-acc')
                }}
              >
                Forgot your password?
              </button>
            </span>
          </div>,
        )
        actions.setLoading(false)
        return true
      }
    })
    if (isError) return
    await felService.setRegistering(store.email, store.password)
    actions.setPosition('verification')
  }
  return (
    <div className={style.signUp}>
      <div className="text lg medium center mb-30">Create account</div>
      <form onSubmit={onSubmit} className={style.login__form}>
        <Input
          placeholder="Email"
          type="email"
          value={store.email}
          onChange={(e) => {
            actions.setEmail(e)
          }}
          error={store.emailError}
        />
        <Input
          placeholder="Password"
          type="password"
          value={store.password}
          onChange={actions.setPassword}
          error={store.passwordError}
        />
        <Input
          placeholder="Confirm password"
          type="password"
          value={repeatPass}
          onChange={setRepeatPass}
          error={repeatPassError}
        />

        <button className="text md">Create account</button>
      </form>
      <button onClick={actions.setPosition.bind(null, 'sign-in')} className="d-block mx-auto mt-30 text sm bold link">
        Sign in
      </button>
      <div className={style.login__line} />
      <Options />
      <Footer />
    </div>
  )
}

export default SingUp
