import React, { useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import classnames from 'classnames'
import style from './style.module.scss'
import Card from '../../components/card'
import { staticFile } from '../../helpers'

interface State {
  currentSlide: number
  itemsCount: number
  slideCount: number
}

const Carousel = () => {
  let slider: Slider | null
  const [state, setState] = useState<State>({
    currentSlide: 0,
    itemsCount: 4,
    slideCount: 1,
  })
  const settings: Settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    beforeChange(_, nextSlide) {
      setState({ ...state, currentSlide: nextSlide })
    },
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // centerMode: true,
          // variableWidth: true,
        },
      },
    ],
  }

  return (
    <div className={style.carousel}>
      <button
        onClick={() => slider?.slickPrev()}
        className={classnames(style.carousel__arrow, {
          [style.carousel__arrow_hide]: state.currentSlide === 0,
        })}
      >
        <img src={staticFile('/slider_arrow.svg')} alt="arrow" />
      </button>
      <Slider ref={(el) => (slider = el)} {...settings} className={`${style.slider} px-10`}>
        <ItemReviews />
        <Item
          user="Iuliana Bia"
          comment="“This app contains a variety of exercises perfect to learn a language. I love the fact that when you're learning a lesson, there aren't 10 words to learn at once, for example. I love that we repeat the same words over and over again until we learn them. I think this app is very useful and creative. I only tried it today and I already have a good opinion about it. You should give it a try at least ^^”"
        />
        <Item
          user="Arie P"
          comment="“Wow! Talk about a hidden gem! For me, it's been hard to find a solid 한국어 (Korean) language learning app. I've tried well over two dozen. But what you have here is very impressive! Love the layout, love the educational approach, and especially in this instance. Wow! I'm just really pleased I found this. Will definitely be learning more with you! I sing the praises here - excellent job! 👏”"
        />
        <Item
          user="Daniel Samasoni"
          comment="“Very simple for those who don't know the language they're learning! I love this app! It keeps me motivated to learn as much as possible every day, and even if I forget some of the older lessons, I could always go back to those lessons and practice using the quizzes! Thank you, devs, for such an awesome app! Keep up the good work! 👍👍👍👍👍”"
        />
        <Item
          user="goo gloo gle"
          comment="“Learning languages while playing games with this amazing app is such a joy. Nice design, simple and elegant. Clear audio.  My goal is to collect millions of flowers and XPs. I have already memorized so many words and phrases thanks to FunEasyLearn and I keep on learning because it is relaxing. Read. Write. Speak. Last, but not least, the FunFunLearn Staff is very responsive and eager to help. Many thanks”"
        />
      </Slider>
      <button
        onClick={() => slider?.slickNext()}
        className={classnames(style.carousel__arrow, style.carousel__arrow_next, {
          [style.carousel__arrow_hide]: state.currentSlide === state.slideCount,
        })}
      >
        <img src={staticFile('/slider_arrow.svg')} alt="arrow" />
      </button>
    </div>
  )
}

export default Carousel

interface ItemProps {
  user: string
  comment: string
}

const Item: React.FC<ItemProps> = ({ user, comment }) => {
  return (
    <div className={`${style.slider__item} px-10 py-10`}>
      <Card className={`px-10 py-20`}>
        <img className={`${style.slider__stars_review} mb-10`} src={staticFile('stars_review.svg')} alt="stars" />
        <div className={`${style.slider__user} text md medium mb-10`}>{user}</div>
        <pre className={`${style.slider__comment} text sm`}>{comment}</pre>
      </Card>
    </div>
  )
}

const ItemReviews = () => (
  <div className={`${style.slider__item} px-10 py-10`}>
    <Card className={`px-10 py-20`}>
      <div className={`${style.slider__bigNum}`}>
        4.7<span>/5</span>
      </div>
      <img className={` mb-10`} style={{ width: '172px' }} src={staticFile('rating.svg')} alt="stars" />
      <div className={`${style.slider__user} text md medium mb-10`}>1,000,000+ reviews</div>
      <pre className={`${style.slider__comment} text sm`}>App Store & Google Play</pre>
    </Card>
  </div>
)
