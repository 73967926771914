import React, { useLayoutEffect, useState } from 'react'
import axios from 'axios'
import parse from 'html-react-parser'

interface Props {
  path: string
  className?: string
}

class Svg extends React.Component<Props> {
  private static cache: {
    path: string
    data: JSX.Element
  }[] = []

  componentDidMount() {
    this.updateChache()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.path !== this.props.path) this.updateChache()
  }

  updateChache() {
    const { path, children, ...props } = this.props
    if (Svg.cache.find((el) => el.path === path)) {
      this.forceUpdate()
    } else {
      axios.get(process.env.STATIC_FILE_URI + path).then(({ data }) => {
        // if (!this.validateSvg(data))
        //   throw new Error(`invalid svg ${process.env.STATIC_FILE_URI}/${path}`)
        const temp = parse(data) as JSX.Element
        const element = React.cloneElement(temp, props)
        Svg.cache.push({
          path,
          data: element,
        })
        this.forceUpdate()
      })
    }
  }

  validateSvg(str: string) {
    return /<svg .+?>.+?<\/svg>/.test(str)
  }

  render() {
    const element = Svg.cache.find((el) => el.path === this.props.path)
    return <>{element ? element.data : 'svg...'}</>
  }
}

export default React.memo(Svg)
