import React, { useEffect } from 'react'
import Input from './input'
import Footer from './footer'
import styles from './login.module.scss'
import { useStore, actions } from './store'
import { auth } from '../../firebase'
import Options from './options'

const SignIn: React.FC = () => {
  const store = useStore()
  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    actions.setEmailError()
    actions.setPasswordError()
    actions.setLoading(true)
    try {
      await auth.signInWithEmailAndPassword(store.email, store.password)
      actions.setShowLogin(false)
    } catch (error) {
      actions.setLoading(false)
      if (['auth/invalid-email', 'auth/user-not-found'].includes(error.code)) {
        actions.setEmailError('Invalid email address')
      } else if ('auth/wrong-password' === error.code) {
        actions.setPasswordError('Incorrect password')
      }
    }
  }
  return (
    <div className={styles.signIn}>
      <div className="text lg medium center mb-30">Sign in</div>
      <form onSubmit={submit} className={styles.login__form}>
        <Input
          placeholder="Email"
          type="email"
          value={store.email}
          onChange={(e) => {
            actions.setEmail(e)
          }}
          error={store.emailError}
        />
        <Input
          placeholder="Password"
          type="password"
          value={store.password}
          onChange={actions.setPassword}
          error={store.passwordError}
        />

        <button className="text md">Sign in</button>
      </form>
      <button className="d-block link text sm bold mx-auto mt-20" onClick={() => actions.setPosition('reset-pass')}>
        Forgot password?
      </button>
      <div className={styles.login__line} />
      <Options />
      <div className="text sm center mt-30">
        <span>Don't have an account?&nbsp;</span>
        <button className="link text sm bold" onClick={() => actions.setPosition('create-acc')}>
          Create now
        </button>
      </div>
      <Footer />
    </div>
  )
}

export default SignIn
