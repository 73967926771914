import { size } from 'polished'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { staticFile } from '../../helpers'
import { useToggle } from '../../hooks'

type Props = {
  type: 'email' | 'password' | 'digit'
  placeholder?: string
  onChange?: (text: string) => void
  value?: string
}

const InputAuth: React.FC<Props> = ({ type, placeholder, onChange, value, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [state, setState] = useState('')
  const [showPass, toggleShowPass] = useToggle(false)
  const inputValue = value ? value : state
  const inputType = type === 'password' ? (showPass ? 'text' : 'password') : type === 'email' ? type : 'text'
  const inputSvg =
    type === 'email' ? '/email.svg' : type === 'password' ? '/password.svg' : type === 'digit' ? '/hashtag.svg' : ''
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setState(value)
    if (onChange) onChange(value)
  }

  const button = (
    <button type="button" onClick={toggleShowPass}>
      {showPass ? <img src={staticFile('showPass.svg')} /> : <img src={staticFile('hidePass.svg')} />}
    </button>
  )

  return (
    <StyledInputAuth {...props}>
      <img src={staticFile(inputSvg)} />
      <input
        ref={inputRef}
        className="text sm"
        type={inputType}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
      />
      {type === 'password' && button}
    </StyledInputAuth>
  )
}

const StyledInputAuth = styled.div`
  ${size('4.8rem', '100%')}
  display: flex;
  align-items: center;
  border: 1px solid #a394a5;
  /* box-sizing: border-box; */
  border-radius: 6px;
  img {
    margin: 0 1.5rem 0 1.8rem;
    width: 1.6rem;
  }
  input {
    &,
    &:active,
    &:focus {
      flex-grow: 1;
      height: 100%;
      padding: 0 1.5rem;
      flex-grow: 1;
      border: none;
      outline: none;
      background-color: transparent;
      border-left: solid 1px #c3b5c6;
    }
  }
  button {
    ${size('100%', '5.4rem')};
    background: transparent;
    img {
      margin: 0;
      width: 2.4rem;
    }
  }
`

export { InputAuth }
