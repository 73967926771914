import React from 'react'
import styled from 'styled-components'
import { staticFile } from '../../helpers'
import { useMeasure } from 'react-use'

const Google = () => {
  const [ref, { width }] = useMeasure<HTMLButtonElement>()
  const size = (width / 100) * 26
  return (
    <Wrapper ref={ref} className="google" style={{ height: size + 'px' }}>
      <div style={{ width: size + 'px' }} className="left">
        <img src={staticFile('/google_logo.svg')} alt="google" />
      </div>
      <div className="right">
        <div
          style={{
            marginBottom: (size / 100) * 2 + 'px',
            fontSize: (size / 100) * 23 + 'px',
          }}
        >
          GET IT ON
        </div>
        <div
          style={{
            fontSize: (size / 100) * 42 + 'px',
            fontWeight: 500,
          }}
        >
          Google Play
        </div>
      </div>
    </Wrapper>
  )
}

const Apple = () => {
  const [ref, { width }] = useMeasure<HTMLButtonElement>()
  const size = (width / 100) * 26
  return (
    <Wrapper ref={ref} className="apple" style={{ height: size + 'px' }}>
      <div style={{ width: size + 'px' }} className="left">
        <img src={staticFile('/apple_logo.svg')} alt="apple" />
      </div>
      <div className="right">
        <div
          style={{
            marginBottom: (size / 100) * 2 + 'px',
            fontSize: (size / 100) * 23 + 'px',
          }}
        >
          Download on the
        </div>
        <div
          style={{
            fontSize: (size / 100) * 42 + 'px',
            fontWeight: 500,
          }}
        >
          App Store
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.button`
  background-color: #000000;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #5f5f5f;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.apple img {
    width: 55%;
  }
  &.google img {
    width: 63%;
  }
  .right {
    color: #fff;
    text-align: start;
    margin-left: 2px;
  }
`

export default {
  google: Google,
  apple: Apple,
}
