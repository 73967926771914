import React from 'react'
import style from './footer.module.scss'
import { staticFile } from '../../helpers'

export const Footer = () => {
  return (
    <footer className={['py-20', style.footer].join(' ')}>
      <div className="text center">© 2020 FunEasyLearn. All rights reserved.</div>
    </footer>
  )
}

export const FooterMedia = () => {
  return (
    <div className={[style.footerMedia].join(' ')}>
      <div className="container d-flex flex-column flex-md-row justify-content-between py-30">
        <div className={[style.links, 'd-flex'].join(' ')}>
          <a href="https://www.funeasylearn.com">Home</a>
          <a href="https://www.funeasylearn.com/about">About</a>
          <a href="https://www.funeasylearn.com/mobile">Mobile Apps</a>
          <a href="https://www.funeasylearn.com/press">Press</a>
          <a href="https://www.funeasylearn.com/terms">Terms</a>
          <a href="https://www.funeasylearn.com/privacy">Privacy</a>
        </div>
        <div className={[style.media, 'd-flex justify-content-center justify-content-md-end  mt-20 mt-md-0'].join(' ')}>
          <a href="https://www.facebook.com/FunEasyLearn">
            <img src={staticFile('media-fb.svg')} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/funeasylearn/">
            <img src={staticFile('media-insta.svg')} alt="instagram" />
          </a>
          <a href="https://www.youtube.com/user/FunEasyLearn">
            <img src={staticFile('media-yt.svg')} alt="youtube" />
          </a>
          <a href="https://twitter.com/FunEasyLearn">
            <img src={staticFile('media-tw.svg')} alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  )
}
