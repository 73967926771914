import React, { useRef } from 'react'
import dayjs from 'dayjs'
import { useStore as useUserStore, actions as userAction } from '../../store/user'
import { breakpoints } from '../../styles/vars'
import { useMedia } from '../../hooks/useMedia'
import { css } from 'styled-components'
import { useMeasure, useClickAway } from 'react-use'
import { staticFile } from '../../helpers'
import Svg from '../../components/svg'
import { useToggle } from '../../hooks'
import { codeToName } from '../redeem/helpers'
import { ISubscription } from './type'
import { useCancelPopup } from './Cancel'
import { ACCESS_TYPE, BUY_FROM } from '../../@types/Subscription'
import { firebase } from '../../firebase'
import { useUpgradeStore } from './Upgrade'

const Manage = () => {
  const subscriptions = useUserStore((state) => state.subscriptions)
  const windowWidth = useMedia()
  const _subscriptions = subscriptions.map((el) => {
    const language = codeToName(el.course_id)
    const type =
      el.access_type === 2 ? 'Subscription with flowers' : el.access_type === 3 ? 'Promo code' : 'Subscription'
    const activated = dayjs(el.start_date).format('DD.MM.YY')
    const expires =
      el.period === 0
        ? 'Lifetime'
        : el.auto_renewing
        ? `Renews on ${dayjs(el.end_date).format('DD.MM.YY')}`
        : dayjs(el.end_date).format('DD.MM.YY')
    const months = el.period === 0 ? 'Lifetime' : el.period > 1 ? `${el.period} months` : `1 month`
    const status =
      el.purchase_status === 1
        ? 'Active'
        : el.purchase_status === 2
        ? 'Canceled'
        : el.purchase_status === 3
        ? 'Grace period'
        : el.purchase_status === 4
        ? 'On hold'
        : el.purchase_status === 5
        ? 'Paused'
        : el.purchase_status === 6
        ? 'Revoked'
        : el.purchase_status === 7
        ? 'Expired'
        : 'Undefined'
    return {
      language,
      type,
      activated,
      expires,
      months,
      status,
      ...el,
    }
  })
  if (windowWidth > breakpoints.xl) return <ManageXl data={_subscriptions} />
  // if (windowWidth > breakpoints.lg) return <ManageLg />
  if (windowWidth > breakpoints.sm) return <ManageMd data={_subscriptions} />
  else return <ManageSm data={_subscriptions} />
}

const SubscriptionStatusColor = (status: string) => {
  switch (status) {
    case 'Active':
      return '#B9EFA5'
    case 'Canceled':
    case 'Expired':
    case 'Revoked':
      return '#CFC5D0'
    case 'Grace period':
    case 'Grace period':
    case 'Paused':
      return '#FDD797'
  }
}

const ManageXl: React.FC<{ data: ISubscription[] }> = ({ data }) => {
  return (
    <div>
      <div // table head
        css={css`
          display: grid;
          grid-template-columns: repeat(50, 1fr);
          grid-column-gap: 4px;
        `}
      >
        <div
          className="text sm center"
          css={`
            grid-column: span 6;
          `}
        >
          Language
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 7;
          `}
        >
          Type
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 6;
          `}
        >
          Period
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 6;
          `}
        >
          Start Date
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 6;
          `}
        >
          End Date
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 7;
          `}
        >
          Status
        </div>
        <div
          className="text sm center"
          css={`
            grid-column: span 12;
          `}
        >
          Manage
        </div>
      </div>
      {data.map((subscription) => (
        <div // table row
          css={css`
            display: grid;
            grid-template-columns: repeat(50, 1fr);
            grid-column-gap: 4px;
            border: 0.5px solid #cec6cf;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.306053);
            border-radius: 6px;
            .value {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
            & > div {
              height: 100%;
            }
            & > div:not(:last-child) {
              position: relative;
              &::before {
                content: '';
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(0, 0, 0, 0.148793);
                border-radius: 4px;
                width: 4px;
                height: 85%;
              }
            }
          `}
          key={subscription.id}
          className="mt-10"
        >
          <div
            className="text sm center"
            css={`
              grid-column: span 6;
            `}
          >
            <div className="value">{subscription.language}</div>
          </div>
          <div
            className="text sm center"
            css={`
              grid-column: span 7;
            `}
          >
            <div className="value">{subscription.type}</div>
          </div>
          <div
            className="text sm center"
            css={`
              grid-column: span 6;
            `}
          >
            <div className="value">{subscription.months}</div>
          </div>
          <div
            className="text sm center"
            css={`
              grid-column: span 6;
            `}
          >
            <div className="value">{subscription.activated}</div>
          </div>
          <div
            className="text sm center"
            css={`
              grid-column: span 6;
            `}
          >
            <div className="value">{subscription.expires}</div>
          </div>
          <div
            className="text sm center"
            css={`
              grid-column: span 7;
              background: ${SubscriptionStatusColor(subscription.status)};
            `}
          >
            <div className="value">{subscription.status}</div>
          </div>
          <div
            className="p-5"
            css={`
              grid-column: span 12;
            `}
          >
            <ManageSelect {...subscription} />
          </div>
        </div>
      ))}
    </div>
  )
}

// const ManageLg = () => {
//   return <div>Lg</div>
// }

const ManageMd: React.FC<{ data: ISubscription[] }> = ({ data }) => {
  return (
    <div className="row">
      {data.map((subscription) => (
        <div key={subscription.id} className="col-sm-12 col-lg-6 mb-20">
          <div
            css={`
              border: 0.5px solid #cec6cf;
              box-sizing: border-box;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.306053);
              border-radius: 6px;
              /* overflow: hidden; */
              .header {
                display: flex;
                justify-content: space-between;
                background: #f0ebf1;
                & > div {
                  width: 50%;

                  font-size: 14px;
                }
              }
              .content {
                display: flex;
                justify-content: space-between;
                min-height: 53px;
                & > div {
                  width: 50%;
                  height: inherit;
                  & > div {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  &:first-child {
                    position: relative;
                    &::before {
                      content: '';
                      position: absolute;
                      left: 100%;
                      top: 50%;
                      transform: translateY(-50%);
                      background: rgba(0, 0, 0, 0.148793);
                      border-radius: 4px;
                      width: 4px;
                      height: 85%;
                    }
                  }
                }
              }
            `}
          >
            <div>
              <div className="header py-5">
                <div className="text center">Language</div>
                <div className="text center">Type</div>
              </div>
              <div className="content">
                <div className="text sm medium center">
                  <div>{subscription.language}</div>
                </div>
                <div className="text sm medium center">
                  <div>{subscription.type}</div>
                </div>
              </div>
              <div className="header py-5">
                <div className="text center">Period</div>
                <div className="text center">Status</div>
              </div>
              <div className="content">
                <div className="text sm medium center">
                  <div>{subscription.months}</div>
                </div>
                <div className="text sm medium center">
                  <div style={{ background: SubscriptionStatusColor(subscription.status), marginLeft: '4px' }}>
                    {subscription.status}
                  </div>
                </div>
              </div>
              <div className="header py-5">
                <div className="text center">Start Date</div>
                <div className="text center">End Date</div>
              </div>
              <div className="content">
                <div className="text sm medium center">
                  <div>{subscription.activated}</div>
                </div>
                <div className="text sm medium center">
                  <div>{subscription.expires}</div>
                </div>
              </div>
            </div>
            <div className="p-5">
              <ManageSelect {...subscription} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const ManageSm: React.FC<{ data: ISubscription[] }> = ({ data }) => {
  return (
    <div>
      {data.map((subscription) => (
        <div className="mb-20" key={subscription.id}>
          <div
            css={`
              border: 0.5px solid #cec6cf;
              box-sizing: border-box;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.306053);
              border-radius: 6px;
              /* overflow: hidden; */
              .header {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f0ebf1;
                & > div {
                  width: 50%;
                  font-size: 14px;
                }
              }
              .content {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 53px;
                div {
                  font-size: 20px;
                  font-weight: 500;
                }
              }
            `}
          >
            <div>
              <div className="header py-5">
                <div className="text center">Language</div>
              </div>
              <div className="content">
                <div>
                  <div>{subscription.language}</div>
                </div>
              </div>

              <div className="header py-5">
                <div className="text center">Type</div>
              </div>
              <div className="content">
                <div>
                  <div>{subscription.type}</div>
                </div>
              </div>

              <div className="header py-5">
                <div className="text center">Period</div>
              </div>
              <div className="content">
                <div>
                  <div>{subscription.months}</div>
                </div>
              </div>
              <div className="header py-5">
                <div className="text center">Status</div>
              </div>
              <div className="content" style={{ background: SubscriptionStatusColor(subscription.status) }}>
                <div>
                  <div>{subscription.status}</div>
                </div>
              </div>
              <div className="header py-5">
                <div className="text center">Start Date</div>
              </div>
              <div className="content">
                <div>
                  <div>{subscription.activated}</div>
                </div>
              </div>
              <div className="header py-5">
                <div className="text center">End Date</div>
              </div>
              <div className="content">
                <div>
                  <div>{subscription.expires}</div>
                </div>
              </div>
            </div>
            <div className="p-5">
              <ManageSelect {...subscription} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const ManageSelect = (subscription: ISubscription) => {
  const cancelSubscription = useCancelPopup((store) => store.cancelSubscription)
  const upgradeSubscription = useUpgradeStore((store) => store.upgradeSubscription)
  const upgradeHidePopup = useUpgradeStore((store) => store.cancel)
  const ref = useRef<HTMLDivElement>(null)
  const [bind, { width, height }] = useMeasure()
  const [show, toggleShow] = useToggle(false)
  useClickAway(ref, () => {
    if (show) toggleShow()
  })

  const isCanceble = subscription.auto_renewing && subscription.period !== 0 && [3, 4].includes(subscription.buy_from)
  const isSubscribable = ACCESS_TYPE.flower === subscription.access_type && !subscription.auto_renewing
  const isUpgradeable =
    BUY_FROM.paddle === subscription.buy_from &&
    ACCESS_TYPE.premium === subscription.access_type &&
    subscription.period !== 0
  const isClickable = isCanceble || isSubscribable || isUpgradeable
  return (
    <div
      ref={ref}
      css={`
        height: 43px;
        position: relative;
        z-index: ${show ? '10' : '1'};
        :hover {
          & > div {
            border-color: ${show ? '#116eee' : '#f03f29'};
            color: ${show ? '#116eee' : '#f03f29'};
          }
          svg {
            fill: ${show ? '#116eee' : '#f03f29'};
          }
        }
        ${!isClickable &&
        css`
          & > div {
            border-color: #6c757d !important;
            color: #6c757d !important;
          }
          svg {
            fill: #6c757d !important;
          }
        `}
      `}
    >
      <div
        css={`
          background: white;
          position: absolute;
          left: 0;
          right: 0;
          border: 1px solid #116eee;
          border-radius: 4px;
          padding: 10px 15px;
          color: #116eee;
          transition: all 0.2s;
          display: block;
        `}
      >
        <div
          className="arrow"
          css={`
            /* display: inline-block; */
            position: absolute;
            display: block;
            right: 15px;
            top: 16px;

            .svg {
              cursor: pointer;
              transform-origin: center center;
              transform: rotateY(180deg) rotateZ(${show ? 90 : -90}deg);
              transition: all 0.2s;
              width: 6px;
              svg {
                fill: #116eee;
              }
            }
          `}
        >
          <div className="svg" onClick={isClickable ? toggleShow : undefined}>
            <Svg path={'/slider_arrow.svg'} />
          </div>
        </div>
        <div
          className="text sm center bold"
          style={{ lineHeight: '2rem' }}
          css={`
            cursor: ${isClickable ? 'pointer' : 'not-allowed'};
            color: ${show ? '#C3DAFB' : 'inherit'};
          `}
          onClick={isClickable ? toggleShow : undefined}
        >
          Manage Subscription
        </div>
        <ul
          css={`
            display: ${show ? 'block' : 'none'};
            li {
              line-height: 1 !important;
              margin-top: 15px;
              cursor: pointer;
              transition: all 0.2s;
              :hover {
                color: #f03f29;
              }
            }
          `}
        >
          {isUpgradeable && (
            <li
              className="text sm center bold"
              onClick={async () => {
                try {
                  await upgradeSubscription(subscription)
                  upgradeHidePopup()
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Upgrade
            </li>
          )}
          {isCanceble && (
            <li
              className="text sm center bold"
              onClick={async () => {
                try {
                  await cancelSubscription(subscription)
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Cancel Subscription
            </li>
          )}
          {isSubscribable && (
            <li
              className="text sm center bold"
              onClick={async () => {
                try {
                  await firebase.functions().httpsCallable('notificationClientFlower')({
                    course_id: subscription.course_id,
                    action: 'subscribe',
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Subscribe
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Manage
