import { useState, useEffect } from 'react'
import Url from 'url-parse'

export function useWindowLocation(): Url {
  const [state, setState] = useState(() => new Url(window.location.href, true))
  useEffect(() => {
    const fn = () => {
      setState(new Url(window.location.href, true))
    }
    window.addEventListener('popstate', fn)
    return () => {
      window.removeEventListener('popstate', fn)
    }
  }, [])
  return state
}
