import React, { memo, useState } from 'react'
import create from 'zustand'
import Popup from '../../components/popup'
import { ISubscription } from './type'
import dayjs from 'dayjs'
import { below } from '../../styles/media-query'
import { fetcher, felService } from '../../services'
import useSWR from 'swr'
import { staticFile } from '../../helpers'
import Loading from '../../components/loading'
import { useToggle, useLoadScript } from '../../hooks'
import { codeToName } from '../redeem/helpers'
import { paddle } from '../../payment'
import { useStore as userStore } from '../../store/user'

const Upgrade = () => {
  const { provider, email, UID } = userStore()
  const [showSuccess, toggleShowSuccess] = useToggle(false)
  const { cancel, show } = useUpgradeStore()
  const [loading, _, setLoading] = useToggle(false)
  const subscription = useUpgradeStore((store) => store.subscription)
  const { data } = useSWR('/products', fetcher.getProducts)
  const [selected, setSelected] = useState(0)
  if (!subscription) return null
  else if (!data) return null
  const products = [
    {
      period: 1,
      course_id: subscription.course_id,
      price: data.one_1month,
      productId: 'one_1month',
    },
    {
      period: 6,
      course_id: subscription.course_id,
      price: data.one_6month,
      productId: 'one_6month',
    },
    {
      period: 12,
      course_id: subscription.course_id,
      price: data.one_12month,
      productId: 'one_12month',
    },
    {
      period: 0,
      course_id: subscription.course_id,
      price: data.one_lifetime,
      productId: 'one_lifetime',
    },
    {
      period: 1,
      course_id: 0,
      price: data.all_1month,
      productId: 'all_1month',
    },
    {
      period: 6,
      course_id: 0,
      price: data.all_6month,
      productId: 'all_6month',
    },
    {
      period: 12,
      course_id: 0,
      price: data.all_12month,
      productId: 'all_12month',
    },
    {
      period: 0,
      course_id: 0,
      price: data.all_lifetime,
      productId: 'all_lifetime',
    },
  ]
  const filteredProducts = (() => {
    const { course_id, period, auto_renewing } = subscription!
    if (!auto_renewing && course_id !== 0) {
      return [products[3], products[7]]
    }
    if (!auto_renewing) {
      return [products[7]]
    }
    if (course_id > 0) {
      return products.filter((el) => el.period > period || el.course_id === 0 || el.period === 0)
      // return products.filter((el) => el)
    }
    return products.slice(4).filter((el) => el.period > period || el.period === 0)
  })()
  const Products = ({
    period,
    course_id,
    price,
    idx,
  }: {
    period: number
    course_id: number
    price: number
    idx: number
  }) => {
    return (
      <div
        onClick={setSelected.bind(null, idx)}
        css={`
          border: 1px solid #116eee;
          border-radius: 4px;
          padding: 10px;
          color: #116eee;
          text-align: center;
          cursor: pointer;
          transition: all 0.2s;
          :hover {
            border: 1px solid #f03f29;
            color: #f03f29;
          }
          ${idx === selected &&
          `
            border: 1px solid #116eee !important;
            color: white !important;
            background: #116eee !important;
          `}
        `}
      >
        <div className="text sm bold">{codeToName(course_id)}</div>
        <div className="text sm bold">
          {period === 0
            ? 'Lifetime'
            : period === 1
            ? '1 month'
            : [6, 12].includes(period)
            ? `${period} months`
            : 'Undefined'}
        </div>
        <div style={{ fontSize: '13px' }} className="mt-10">
          ${price}{' '}
          {period === 0
            ? 'one time payment'
            : period === 1
            ? 'every month'
            : [6, 12].includes(period)
            ? `every ${period} months`
            : 'Undefined'}
        </div>
      </div>
    )
  }

  const handleUpgrade = async () => {
    setLoading(true)
    if (filteredProducts[selected].period !== 0) {
      // @ts-ignore
      await felService.upgradeSubscription(subscription.id, filteredProducts[selected].productId)
    }
    // id, productId
    else if (filteredProducts[selected].period === 0) {
      // @ts-ignore
      const link = await felService.getPaddleOverrideLink(filteredProducts[selected].productId)
      console.log(link)
      await paddle.checkout(link, email, {
        UID,
        courseId: filteredProducts[selected].course_id,
      })
      if (subscription.auto_renewing) {
        const res = await felService.cancelSubscription(subscription.id)
        console.log(res)
      }
    }
    toggleShowSuccess()
    setLoading(false)
  }

  const selection = (
    <>
      <div className="text md center medium mb-10">{subscription?.language}</div>
      <div className="text sm center">Activated: {subscription?.activated}</div>
      <div className="text sm center">Expires on: {dayjs(subscription?.end_date).format('DD.MM.YY')}</div>
      <div
        className="mt-30"
        css={`
          display: grid;
          grid-gap: 20px 30px;
          grid-template-columns: repeat(
            ${filteredProducts.length > 4
              ? 3
              : filteredProducts.length === 4
              ? 2
              : filteredProducts.length === 3
              ? 3
              : 2},
            1fr
          );
          ${below.sm} {
            grid-template-columns: repeat(2, 1fr);
          }
        `}
      >
        {filteredProducts.map((el, idx) => (
          <Products key={idx} idx={idx} course_id={el.course_id!} period={el.period} price={el.price!} />
        ))}
      </div>
      <button
        onClick={handleUpgrade}
        className="text md"
        css={`
          width: 100%;
          padding: 15px;
          width: 100%;
          color: white;
          background: #3d85ea;
          border-radius: 1000px;
          transition: all 0.2s;
          margin-top: 30px !important;
          ${below.sm} {
            margin: 0 auto;
            width: 90%;
          }
          &:hover,
          &.hover {
            background-color: #8bbbff;
          }
        `}
      >
        Upgrade
      </button>
    </>
  )
  const success = (
    <div>
      <div className="text md center medium mb-10">Your upgrade was successful</div>
      <div className="text sm center mb-10">
        Congratulations! You have upgraded your FunEasyLearn Premium Subscription. Nou you have a{' '}
        {filteredProducts[selected].period === 0
          ? 'lifetime'
          : filteredProducts[selected].period === 1
          ? '1 month'
          : `${filteredProducts[selected].period} months`}{' '}
        subscription for{' '}
        {filteredProducts[selected].course_id === 0 ? 'all courses' : `${subscription.language} course`}.
        {filteredProducts[selected].period === 0
          ? ''
          : ` From now on you will be charged $${filteredProducts[selected].price} every ${
              filteredProducts[selected].period
            } ${filteredProducts[selected].period === 1 ? 'month' : 'months'}.`}
      </div>
    </div>
  )
  return (
    <Popup
      css={`
        .content {
          max-width: 570px;
        }
      `}
      onClose={cancel}
    >
      {data && loading && <Loading src={staticFile('loading_v2.json')} />}
      {showSuccess ? success : selection}
    </Popup>
  )
}

export default memo(Upgrade)

type Store = {
  show: boolean
  cancel: () => void
  upgradeSubscription: (subscription: ISubscription) => Promise<void>
  promiseResolve?: (cb: (res: () => void, rej: () => void) => void) => void
  subscription?: ISubscription
}

export const useUpgradeStore = create<Store>((set, get) => ({
  show: false,
  upgradeSubscription: (subscription) => {
    set({ show: true, subscription })
    const { promiseResolve } = get()
    if (promiseResolve) {
      promiseResolve((_, rej) => rej())
    }
    return new Promise((res, rej) => {
      set({
        promiseResolve: (cb) => {
          set({
            promiseResolve: undefined,
          })
          cb(res, rej)
        },
      })
    })
  },
  cancel: () => {
    set({ show: false })
  },
}))
