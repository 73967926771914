import create from 'zustand'

type Position = 'profile' | 'subscription'

type Store = {
  position: Position
}

const useStore = create<Store>(() => ({
  position: 'subscription',
}))

export const action = {
  setPosition(position: Position) {
    useStore.setState({ position })
  },
}

export { useStore }
