import React from 'react'
import shallow from 'zustand/shallow'
import Svg from '../svg'
import { actions } from './store'
import { actions as loginActions } from '../../components/login/store'
import { useStore as userStore, actions as userActions } from '../../store/user'
import style from './menu.module.scss'
const UserInfo = () => {
  const { provider, displayName, email } = userStore((state) => state, shallow)

  const iconPath =
    provider === 'google.com'
      ? 'provider-google-color'
      : provider === 'facebook.com'
      ? 'provider-fb-color'
      : provider === 'hotmail.com' || provider === 'microsoft.com'
      ? 'provider-ms-color'
      : provider === 'yahoo.com'
      ? 'provider-yh-color'
      : 'provider-email-color'

  return (
    <div className={style.menu__userInfo}>
      <div>
        {displayName && <h1>{displayName}</h1>}
        {email && (
          <h2 className="text xs">
            <Svg path={`/${iconPath}.svg`} />
            <span>{email}</span>
          </h2>
        )}
        {displayName || email ? (
          <button className="text sm medium" onClick={userActions.signOut.bind(null)}>
            Sign out
          </button>
        ) : (
          <button
            className="text sm medium"
            onClick={() => {
              loginActions.setShowLogin(true)
              actions.toggleMenu(false)
            }}
          >
            Sign in
          </button>
        )}
      </div>
    </div>
  )
}

export default UserInfo
