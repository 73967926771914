import { EventEmitter } from 'events'

const event = new EventEmitter()

class Payment {
  private vendor: number
  private paddle: any
  private mem = true
  constructor({ vendor }: { vendor: number }) {
    this.vendor = vendor
  }
  checkout(override: string, email: string, payload?: object) {
    return new Promise((res, rej) => {
      event.removeAllListeners()
      this.initPaddle()
      this.paddle.Checkout.open({
        email,
        override,
        passthrough: JSON.stringify(payload),
      })
      event.once('close', rej)
      event.once('complete', res)
    })
  }
  private initPaddle() {
    // @ts-ignore
    this.paddle = Paddle
    // console.log(this.paddle)
    // @ts-ignore
    Paddle.Setup({
      vendor: this.vendor,
      eventCallback: function (data: any) {
        console.log(data.event)
        if (data.event === 'Checkout.Complete') event.emit('complete')
        if (data.event === 'Checkout.Close') event.emit('close')
      },
    })
    // if (this.mem) {
    //   console.log('init')
    //   this.paddle.Setup({
    //     vendor: this.vendor,
    //     eventCallback: function (data: any) {
    //       console.log(data.event)
    //       if (data.event === 'Checkout.Complete') event.emit('complete')
    //       if (data.event === 'Checkout.Close') event.emit('close')
    //     },
    //   })
    //   this.mem = false
    // }
  }

  getAffiliateId(): string | undefined {
    const name = 'paddlejs_campaign_affiliate'
    let json = document.cookie.split(';').filter((str) => str.includes(name))[0]
    if (json) json = json.split('=')[1]
    const obj = JSON.parse(json || '{}')
    if ('affiliate' in obj) return obj['affiliate']
    return
  }
}

export const paddle = new Payment({
  vendor: Number(process.env.PADDLE_VENDOR_ID),
})

class CoinBase {}
