import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './styles/sass/main.sass'

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root'),
)

console.log('welcome')
