import React from 'react'
import styled from 'styled-components'
import { staticFile } from '../../helpers'
import Card from '../../components/card'
import { codeToName } from '../redeem/helpers'
import dayjs from 'dayjs'
import download from '../../components/download'
import { FooterMedia, Footer } from '../../components/footer'

const Succes = ({
  courseId,
  month,
  trial,
}: // type,
{
  courseId: number
  month: number
  trial: number
  // type: 'crypto' | 'trial' | 'normal'
}) => {
  return (
    <>
      <SuccesStyled>
        <section className="intro" style={{ paddingBottom: '6rem' }}>
          <div className="container">
            <div className="row flex-column flex-lg-row align-items-center align-items-lg-start">
              <div className="col-6 col-sm-5 col-md-4 col-lg-4">
                <img src={staticFile('/after_buy_head.png')} alt="Welcome bee" />
              </div>
              <div className="col">
                <div className="text xl center bold">Welcome to FunEasyLearn!</div>
                {trial ? (
                  <div className="text lg center mt-15 mt-lg-50">
                    You have started your <b>FREE TRIAL</b> period
                  </div>
                ) : (
                  <div className="text lg center mt-15 mt-lg-50">
                    You have bought a subscription for {courseId === 0 ? '34 language courses' : '1 language course'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 col-xl-7">
                {trial ? (
                  <Card className="py-20">
                    <div className="text md center">
                      <div>
                        Language: <span className="text medium">{codeToName(courseId!)}</span>
                      </div>
                      <div className="mt-5">
                        Activated Free Trial:{' '}
                        <span className="text medium">{dayjs(Date.now()).format('MMMM D, YYYY')}</span>
                      </div>
                      {courseId !== 0 && (
                        <div className="mt-5">
                          Subscription Date:{' '}
                          <span className="text medium">
                            {dayjs(Date.now()).add(trial, 'day').format('MMMM D, YYYY')}
                          </span>
                        </div>
                      )}
                      <div className="mt-5">
                        Subscription Plan:{' '}
                        <span className="text medium">
                          {month === 0 ? 'Lifetime' : month === 1 ? '1 month' : `${month} months`}
                        </span>
                      </div>
                    </div>
                  </Card>
                ) : (
                  <Card className="py-20">
                    <div className="text md center">
                      <div>
                        Language: <span className="text medium">{codeToName(courseId!)}</span>
                      </div>
                      <div className="mt-5">
                        Plan:{' '}
                        <span className="text medium">
                          {month === 0 ? 'Lifetime' : month === 1 ? '1 month' : `${month} months`}
                        </span>
                      </div>
                      <div className="mt-5">
                        Purchase date: <span className="text medium">{dayjs(Date.now()).format('MMMM D, YYYY')}</span>
                      </div>
                      {month !== 0 && (
                        <div className="mt-5">
                          Renewal date:{' '}
                          <span className="text medium">
                            {dayjs(Date.now()).add(month, 'M').format('MMMM D, YYYY')}
                          </span>
                        </div>
                      )}
                    </div>
                  </Card>
                )}
              </div>
              <div className="col-12 text lg center bold mt-30 mb-15">What do you do now</div>
              <div className="col-12 col-lg-8 col-xl-7">
                <div className="list d-flex">
                  <div className="liNumber mr-10">1</div>
                  <div className="text md">Download the app:</div>
                </div>
                <div className="row align-items-center aling-items-md-initial justify-content-center flex-column flex-md-row">
                  <div className="col-8 col-sm-6 col-md-5 mb-20 mb-md-0">
                    <download.apple />
                  </div>
                  <div className="col-8 col-sm-6 col-md-5">
                    <download.google />
                  </div>
                </div>
                <div className="list d-flex mt-15">
                  <div className="liNumber mr-10">2</div>
                  <div className="text md">Sign in with this account to your app;</div>
                </div>
                {courseId > 0 && (
                  <div className="list d-flex mt-15">
                    <div className="liNumber mr-10">3</div>
                    <div className="text md">Choose as your course language, the language you have just bought;</div>
                  </div>
                )}
                <div className="list d-flex mt-15">
                  <div className="liNumber mr-10">{courseId > 0 ? '4' : '3'}</div>
                  <div className="text md">Enjoy the FunEasyLearning journey.</div>
                </div>
                {courseId > 0 && (
                  <div className="text md ml-50 mt-15">
                    <b>Note:</b> The Lifetime Subscription and all the unlocked contents and features it carries will
                    apply only to the bought language course.
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-60"
          style={{
            backgroundColor: '#FFD558',
            clipPath: 'polygon(0 0, 100% 30px, 100% 100%, 0% 100%)',
          }}
        >
          <div className="container">
            <div className="text lg medium center mb-10">Get closer to your goal</div>
            <div className="row align-items-center flex-column">
              <div className="col-lg-8 col-xl-7">
                <div className="text md center">
                  Learn languages, memorize vocabulary units, complete levels and develop speaking the fun & easy way.
                </div>
              </div>

              <div className="col-12 col-lg-8 col-xl-7 mt-30 flex-column flex-md-row d-flex">
                <img
                  className="align-self-center align-self-lg-start"
                  style={{ width: '72px' }}
                  src={staticFile('icon-brain.svg')}
                  alt="dart"
                />
                <div
                  css={`
                    @media (max-width: 755px) {
                      text-align: center;
                    }
                  `}
                  className="ml-0 ml-md-15 mt-5 mb-md-0"
                >
                  <div className="text md bold mb-10">Powerful Vocabulary Builder </div>
                  <div className="text sm">
                    Build an impressive vocabulary consisting of 6,000 words by playing 30 fun and effective games.
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8 col-xl-7 mt-30 flex-column flex-md-row d-flex">
                <img
                  className="align-self-center align-self-lg-start"
                  style={{ width: '72px' }}
                  src={staticFile('icon-circle.svg')}
                  alt="dart"
                />
                <div
                  css={`
                    @media (max-width: 755px) {
                      text-align: center;
                    }
                  `}
                  className="ml-0 ml-md-15 mt-5 mb-md-0"
                >
                  <div className="text md bold mb-10">Useful Phrasebook </div>
                  <div className="text sm">
                    Take part in any business, travelling or daily conversations with the natives by speaking 5,000
                    phrases, sentences and expressions.
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8 col-xl-7 mt-30 flex-column flex-md-row d-flex">
                <img
                  className="align-self-center align-self-lg-start"
                  style={{ width: '72px' }}
                  src={staticFile('icon-dart.svg')}
                  alt="dart"
                />
                <div
                  css={`
                    @media (max-width: 755px) {
                      text-align: center;
                    }
                  `}
                  className="ml-0 ml-md-15 mt-5 mb-md-0"
                >
                  <div className="text md bold mb-10">Amazing Features</div>
                  <div className="text sm">
                    Use of all of our learning tools for your advantage – Search, Review, Learn Just to Speak, Hide Word
                    or Sentence, and all the other features we are going to launch, after your purchase.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </SuccesStyled>
      <FooterMedia />
      <Footer />
    </>
  )
}

const SuccesStyled = styled.div`
  .intro {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #ffd558;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3rem), 0 100%);
  }
  .liNumber {
    flex-shrink: 0;
    font-size: 22px;
    font-weight: 700;
    color: white;
    border-radius: 50%;
    background: #f1bf00;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
  }
`

export default Succes
