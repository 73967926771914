import { useLayoutEffect } from 'react'

export function useScrollFreeze() {
  useLayoutEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      throw new Error(
        `body tag is required if you want to use this hook (useScrollFreeze)`,
      )
    }
    body.style.overflowY = 'hidden'
    console.dir()
    return () => {
      body.style.overflowY = 'initial'
    }
  }, [])
}
