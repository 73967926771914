import create from 'zustand'

type Position = 'first' | 'promocode' | 'select' | 'finish'

type Redeem = {
  position: Position
  isPromocodeFromLink: boolean
  promocode: string
  selected: {
    course_id: number
    period: number
  }
}

const initialState: Redeem = {
  position: 'first',
  isPromocodeFromLink: false,
  promocode: '',
  selected: {
    course_id: -1,
    period: -1,
  },
}

const useRedeemState = create<Redeem>(() => ({ ...initialState }))

const redeemActions = {
  // setCode(code: string) {
  //   useRedeemState.setState({ code })
  // },
  // setActivated(activated: boolean) {
  //   useRedeemState.setState({ activated })
  // },
  setSelected(course_id: number, period: number) {
    useRedeemState.setState({ selected: { course_id, period } })
  },
  setPosition(position: Position) {
    useRedeemState.setState({ position })
  },
  setInitCode(initCode: string) {
    useRedeemState.setState({
      promocode: initCode,
      isPromocodeFromLink: true,
    })
  },
  setPromocode(promocode: string) {
    useRedeemState.setState({ promocode })
  },
  setSelectedPeriod(period: number) {
    useRedeemState.setState({ selected: { ...useRedeemState.getState().selected, period } })
  },
}

export { useRedeemState, redeemActions }
