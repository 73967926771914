import React from 'react'
import classname from 'classnames'
import styles from './menu.module.scss'

interface Props {
  className?: string
  as?: string
  close?: true
  href?: string
  onClick?: () => void
}

const Item: React.FC<Props> = ({ children, className, close, href, onClick, as = 'button' }) =>
  React.createElement(
    as,
    {
      href,
      className: classname('text sm', styles.menu__item, className, {
        [styles['menu__item--close']]: close,
      }),
      onClick,
    },
    children,
  )

export default Item

const a = <a></a>
