import React from 'react'
import { Head } from './Header'
import { actions as loginActions } from '../../components/login/store'
import style from './style.module.scss'
import Svg from '../../components/svg'

export default () => {
  return (
    <div className={style.signIn}>
      <Head />
      <section>
        <div className="container">
          <h3 className="text lg center">To redeem your code you need to</h3>
          <div className="row flex-column flex-md-row justify-content-between align-items-center text md medium mt-30">
            <div className="col-sm-8 col-md-5">
              <button
                onClick={() => {
                  loginActions.setShowLogin(true)
                  loginActions.setPosition('create-acc')
                }}
                className="button text medium uppercase"
              >
                create an account
              </button>
            </div>
            <span className="my-10">or</span>
            <div className="col-sm-8 col-md-5" onClick={loginActions.setShowLogin.bind(null, true)}>
              <button className="button text medium uppercase">sign in</button>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: '#ffd558' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="d-flex">
                <Svg path="/alert.svg" className={`${style.alert} mr-15`} />
                <p className="text md">
                  Make sure you sign in to the same account you have used to sign in to the app, otherwise, you will not
                  be able to apply this purchase to your app account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
