import React, { useEffect, createContext, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from '@reach/router'
import style from './style.module.scss'
import { useRedeemState, redeemActions } from './redeem-state'
import SignIn from './SignIn'
import Promocode from './Promocode'
import SelectCourse from './Select'
import Success from './Success'
import { useStore as userStore } from '../../store/user'
import { Footer, FooterMedia } from '../../components/footer'
import shallow from 'zustand/shallow'
import { useSpring } from 'react-spring'

const Redeem: React.FC<RouteComponentProps> = ({ location, navigate }) => {
  const state = useRedeemState((state) => state, shallow)
  const UID = userStore((state) => state.UID)
  const [, setY] = useSpring(() => ({ y: 0 }))
  useEffect(() => {
    if (navigate && location && location.search) {
      const code = location.search.match(/code=([^&]*)(?=&|$)/)
      if (code) {
        redeemActions.setInitCode(code[1])
      }
      navigate('/redeem')
    }
  }, [location?.search])
  useEffect(() => {
    if (UID) {
      redeemActions.setPosition('promocode')
    } else {
      redeemActions.setPosition('first')
    }
  }, [UID])
  useEffect(() => {
    setY({
      y: 0,
      reset: true,
      from: { y: window.scrollY },
      // @ts-ignore
      onFrame: (props) => window.scroll(0, props.y),
    })
    if (state.position === 'first' && !state.isPromocodeFromLink) {
      redeemActions.setPromocode('')
    }
  }, [state.position])
  return (
    <div className={style.redeem}>
      <Helmet>
        <title>Redeem your code - FunEasyLearn</title>
        <meta
          name="description"
          content="Redeem your code for a FunEasyLearn subscription and start learning right away. Follow these simple steps to be able to get access to the full FunEasyLearn Experience. Do it now!"
        />
        <meta property="og:title" content="Redeem your code - FunEasyLearn" />
        <meta
          property="og:description"
          content="Redeem your code for a FunEasyLearn subscription and start learning right away. Follow these simple steps to be able to get access to the full FunEasyLearn Experience. Do it now!"
        />
        <meta property="twitter:title" content="Redeem your code - FunEasyLearn" />
        <meta
          property="twitter:description"
          content="Redeem your code for a FunEasyLearn subscription and start learning right away. Follow these simple steps to be able to get access to the full FunEasyLearn Experience. Do it now!"
        />
      </Helmet>
      {(state.position === 'first' && <SignIn />) ||
        (state.position === 'promocode' && <Promocode />) ||
        (state.position === 'select' && <SelectCourse />) ||
        (state.position === 'finish' && <Success />) ||
        null}
      <FooterMedia />
      <Footer />
    </div>
  )
}

export default Redeem
