import React, { useState } from 'react'
import moment from 'moment'
import { useInterval } from 'react-use'

const Box: React.FC<{ text: string; value: number }> = ({ text, value }) => {
  return (
    <div
      css={`
        background: #ffffff;
        border: 1px solid #cec6cf;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 10px 0;
      `}
    >
      <div className="text md bold center">{value > 9 ? value : `0${value}`}</div>
      <div className="text center" style={{ fontSize: '12px' }}>
        {text}
      </div>
    </div>
  )
}

const HurryUp = () => {
  const [state, setState] = useState<[number, number, number]>([0, 0, 0])
  const [h, m, s] = state
  useInterval(() => {
    const seconds = Number(moment().startOf('day').add(1, 'day').format('X')) - Math.round(Date.now() / 1000)
    const h = Math.floor(seconds / 60 / 60)
    let r = seconds - h * 60 * 60
    const m = Math.floor(r / 60)
    r = r - m * 60
    const s = r
    setState([h, m, s])
  }, 1000)
  return (
    <div>
      <div className="text sm bold center">Hurry up! The offer ends soon!</div>
      <div
        css={`
          display: flex;
          justify-content: center;
          margin: 15px 0;
          & > div {
            margin: 0 5px;
            width: 56px;
          }
        `}
      >
        <Box text="hours" value={h} />
        <Box text="minutes" value={m} />
        <Box text="seconds" value={s} />
      </div>
    </div>
  )
}

export default HurryUp
