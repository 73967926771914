import React from 'react'
import create from 'zustand'
import { staticFile } from '../../helpers'
import { FooterMedia, Footer } from '../../components/footer'
import Colors from '../redeem/colors.json'
import styled from 'styled-components'
import { codeToName } from '../redeem/helpers'
import Card from '../../components/card'

const colors: {
  [key: number]: {
    top: string
    bottom: string
  }
} = Colors

const StyledCourse = styled.div`
  ${Card} {
    overflow: hidden;
  }
  .img {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .top,
    .bottom {
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .top {
      top: 0;
      bottom: 50%;
    }
    .bottom {
      top: 50%;
      bottom: 0;
    }
  }
  button {
    width: 80%;
    height: 48px;
    background-color: #3d85ea;
    border-radius: 1000px;
    color: white;
    transition: all 0.2s;
    margin-bottom: 10px;
    :hover {
      background-color: #8bbbff;
    }
  }
`

export const SelectCourse = () => {
  const promiseResolve = useSelectStore((store) => store.promiseResolve!)
  // () => promiseResolve((res) => res(1))
  const courses = [
    3,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    80,
    20,
    21,
    24,
    25,
    28,
    29,
    30,
    32,
    34,
    35,
    37,
    44,
    46,
    65,
    47,
    48,
    49,
    51,
    53,
    55,
    58,
    59,
    60,
  ]
  return (
    <>
      <section style={{ background: '#FFD558' }} className="intro">
        <div className="container">
          <div className="text xl medium center mb-10">Choose the Course</div>
          <img src={staticFile('/choose_course_head.png')} alt="Bee with Phone" />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-center justify-content-lg-start">
            {courses.map((course: number) => (
              <div className="col-11 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-20" key={course}>
                <StyledCourse>
                  <Card className="d-flex flex-column align-items-center">
                    <div className="img">
                      <img src={staticFile(`courses/${course}.png`)} alt={codeToName(course)} />
                      <div className="top" style={{ background: `${colors[course].top}` }} />
                      <div className="bottom" style={{ background: `${colors[course].bottom}` }} />
                    </div>
                    <div className="text medium sm mb-10 mt-5">{codeToName(course)}</div>
                    <button className="text sm mb-10" onClick={() => promiseResolve((res) => res(course))}>
                      Choose
                    </button>
                  </Card>
                </StyledCourse>
              </div>
            ))}
          </div>
        </div>
      </section>
      <FooterMedia />
      <Footer />
    </>
  )
}

type SelectStore = {
  show: boolean
  promiseResolve?: (cb: (res: (courseId: number) => void, rej: (reason?: any) => void) => void) => void
  getCourse: () => Promise<number>
}

export const useSelectStore = create<SelectStore>((set, get) => ({
  show: false,
  getCourse: () => {
    set({
      show: true,
    })
    const { promiseResolve } = get()
    if (promiseResolve) {
      promiseResolve((res, rej) => rej())
    }

    return new Promise((res, rej) => {
      set({
        promiseResolve: (cb) => {
          set({
            show: false,
            promiseResolve: undefined,
          })
          cb(res, rej)
        },
      })
    })
  },
}))
