import styled from 'styled-components'
import { lighten } from 'polished'

const minWidth = (size: 'small' = 'small') => {
  switch (size) {
    case 'small':
      return '160px'
  }
}

const fontSize = (size: 'small' = 'small') => {
  switch (size) {
    case 'small':
      return '18px'
  }
}

const fontWeight = (size: 'medium' = 'medium') => {
  switch (size) {
    case 'medium':
      return '500'
  }
}

const Button = styled.button.attrs((props) => {
  let backgroundColor = '#1476df'
  let textColor = 'white'
  let textColorHover = 'white'
  let borderColor = '#1476df'
  let borderRadius = '1000px'
  const size = 'small'
  // if theme blue transparent
  return {
    minWidth: minWidth(size),
    fontSize: fontSize(size),
    fontWeight: fontWeight(),
    textColor,
    borderColor,
    borderRadius,
    textColorHover,
    backgroundColor,
  }
})`
  padding: 12px 10px;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  min-width: ${(props) => props.minWidth};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &:hover {
    background-color: ${(props) => lighten(0.2, props.backgroundColor)};
    color: ${(props) => props.textColorHover};
  }
`

export default Button
