import { useEffect, useState } from 'react'
import translation from '../assets/email-handler-translation.json'

export const useTranslation = (langId: keyof typeof translation): typeof translation[keyof typeof translation] => {
  const [state, setState] = useState(translation[langId] ? translation[langId] : translation['16'])
  useEffect(() => {
    setState(translation[langId] ? translation[langId] : translation['16'])
  }, [langId])
  if (state) return state
  throw new Error('Tranlation file not found or lang id undefined')
}
