import React, { useState } from 'react'
import Button from './button'
import Input from './input'
import { auth } from '../../firebase'
import { felService } from '../../services'
import { useStore, actions } from './store'
import style from './login.module.scss'
import { useIncrement } from '../../hooks'

const ResetPass = () => {
  const store = useStore()
  const [sendEmailCount, incSendEmail] = useIncrement(0)

  const sendEmail = async () => {
    actions.setLoading(true)
    await felService.resetPassword(store.email)
    actions.setLoading(false)
    incSendEmail()
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    actions.setLoading(true)
    auth.signInWithEmailAndPassword(store.email, '_____________________').catch(async (err) => {
      console.log(err)
      if (err.code === 'auth/wrong-password') {
        await sendEmail()
      } else {
        actions.setLoading(false)
        actions.setEmailError(
          <div>
            <span style={{ color: '#9A0000' }}>Account doesn’t exist.&nbsp;</span>
            <span>
              <button
                className="text sm bold link"
                onClick={() => {
                  actions.setPosition('create-acc')
                }}
              >
                Create account?
              </button>
            </span>
          </div>,
        )
      }
    })
  }

  return (
    <div>
      <div className="text lg medium center">Password Reset</div>
      {sendEmailCount === 0 ? (
        <>
          <div className="text sm center mb-30 mt-10">
            Please enter your email address. We will send you an email with instructions on how to reset your password.
          </div>
          <form className={style.login__form} onSubmit={onSubmit}>
            <Input
              placeholder="Email"
              type="email"
              value={store.email}
              onChange={(e) => {
                actions.setEmail(e)
              }}
              error={store.emailError}
            />

            <button className="text md">Send</button>
          </form>
          <button
            onClick={actions.setPosition.bind(null, 'sign-in')}
            className="d-block text sm bold link mx-auto mt-30"
          >
            Back
          </button>
        </>
      ) : (
        <>
          <div className="text sm center mt-10">We have sent you instructions on how to reset your password.</div>
          {sendEmailCount > 1 && (
            <div className="text sm center error mt-10" style={{ color: '#9a0000' }}>
              Please add accounts@funeasylearn.com to your contacts, just to make sure you receive all the emails we
              send you.
            </div>
          )}
          <form
            className={`${style.login__form}`}
            onSubmit={(e) => {
              e.preventDefault()
              actions.setPosition('sign-in')
            }}
          >
            <button className="text md">Continue</button>
          </form>
          <button onClick={sendEmail} className="d-block mx-auto mt-30 text sm bold link">
            Resend email?
          </button>
        </>
      )}
    </div>
  )
}

export default ResetPass
