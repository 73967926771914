import React, { useRef, memo, useEffect } from 'react'
import { useClickAway } from 'react-use'
import { useStore, actions } from './store'
import styles from './login.module.scss'
import SignIn from './signIn'
import Loading from './loading'
import SignUp from './sign-up'
import ResetPass from './reset-password'
import { useScrollFreeze } from '../../hooks'
import Verification from './verification'

const Login = () => {
  useScrollFreeze()
  const refBox = useRef(null)
  const state = useStore()

  useClickAway(refBox, onClose)

  return (
    <div className={styles.login}>
      <div className={styles.login__container} ref={refBox}>
        {state.position === 'sign-in' ? (
          <SignIn />
        ) : state.position === 'create-acc' ? (
          <SignUp />
        ) : state.position === 'reset-pass' ? (
          <ResetPass />
        ) : state.position === 'verification' ? (
          <Verification />
        ) : (
          <h1>Undefined</h1>
        )}
        <CloseButton />
        {state.loading && <Loading />}
      </div>
    </div>
  )
}

const CloseButton = () => (
  <div className={styles.login__closeBtn} onClick={onClose}>
    <span></span>
    <span></span>
    <span></span>
  </div>
)

const onClose = () => {
  actions.setShowLogin(false)
}

export default memo(Login)
