import React, { useMemo } from 'react'
import { Router } from '@reach/router'
import { SWRConfig } from 'swr'
import Purchase from '../pages/purchase'
import Login from '../components/login'
import { useStore as useLoginStore } from '../components/login/store'
import Menu from '../components/menu'
import Navbar from '../components/navbar'
import Redeem from '../pages/redeem'
import Main from '../pages/main'
import PremiumTest from '../pages/premium-test'
import EmailHandler from '../pages/email-handler'

const App = () => {
  const showLogin = useLoginStore((state) => state.showLogin)
  return useMemo(() => {
    return (
      <>
        <SWRConfig
          value={{
            fetcher: (resource, init) => fetch(process.env.SERVER_API_URI + resource, init).then((res) => res.json()),
          }}
        >
          <Navbar />
          <Router>
            <Main path="/" />
            <Purchase path="premium" />
            <Redeem path="redeem" />
            <PremiumTest path="premium-test" />
            <EmailHandler path="auth/action" />
          </Router>
          {showLogin && <Login />}
          <Menu />
        </SWRConfig>
      </>
    )
  }, [showLogin])
}

export default App
