export interface StatusType {
  access_type: ACCESS_TYPE
  period: number
  start_date: number
  end_date: number
  auto_resume: number
  auto_renewing: boolean
  purchase_status: PURCHASE_STATUS
  buy_from: BUY_FROM
  package_name: string
  product_id: string
  course_id: number
}

export enum ACCESS_TYPE {
  lifetime = 0,
  premium = 1,
  flower = 2,
  promocode = 3,
  remove_ads = 4,
}

export enum PURCHASE_STATUS {
  active = 1,
  canceled = 2,
  grace = 3,
  hold = 4,
  pause = 5,
  revoked = 6,
  expired = 7,
}

export enum BUY_FROM {
  google = 1,
  apple = 2,
  paddle = 3,
  flower = 4,
  intern = 5,
}

export enum COURSE_MAPPING {
  'languages.all' = 0,
  languages = 0,
  afrikaans = 1,
  albanian = 2,
  'english.american' = 80,
  amharic = 66,
  arabic = 3,
  azerbaijani = 4,
  belarusian = 7,
  bengali = 6,
  bosnian = 67,
  brazilian = 65,
  bulgarian = 8,
  catalan = 9,
  chinese = 10,
  croatian = 12,
  czech = 13,
  danish = 14,
  dutch = 15,
  english = 16,
  esperanto = 17,
  estonian = 18,
  tagalog = 19,
  finnish = 20,
  french6000 = 21,
  georgian = 23,
  german = 24,
  greek = 25,
  hebrew = 28,
  hindi = 29,
  hungarian = 30,
  icelandic = 31,
  igbo = 70,
  indonesian = 32,
  irish = 33,
  italian = 34,
  japanese = 35,
  javanese = 74,
  kazakh = 71,
  khmer = 75,
  korean = 37,
  latvian = 39,
  lithuanian = 40,
  macedonian = 41,
  malay = 42,
  mexican = 77,
  mongolian = 69,
  nepali = 72,
  norwegian = 44,
  persian = 45,
  polish = 46,
  portuguese = 47,
  romanian = 48,
  russian = 49,
  serbian = 50,
  slovak = 51,
  slovenian = 52,
  spanish = 53,
  swahili = 54,
  swedish = 55,
  thai = 58,
  chinese_traditional = 11,
  turkish = 59,
  ukrainian = 60,
  urdu = 61,
  vietnamese = 62,
  yoruba = 78,
  zulu = 79,
}

export enum NOTIFICATION_TYPE {
  recovered = 1,
  renewed = 2,
  canceled = 3,
  purchased = 4,
  on_hold = 5,
  in_grace = 6,
  restarted = 7,
  paused = 10,
  pause_schedule_changed = 11,
  revoked = 12,
  expired = 13,
}

export enum HINTS {
  easy = 1,
  smart = 2,
  crown = 3,
}
