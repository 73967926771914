import React, { useState, useEffect } from 'react'
import { staticFile } from '../../helpers'
import { auth } from '../../firebase'

export const HandleRecoverEmail: React.FC<{ actionCode: string }> = ({ actionCode }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    setLoading(true)
    auth
      .checkActionCode(actionCode)
      .then((info) => {
        setEmail(info.data.email!)
        return auth.applyActionCode(actionCode)
      })
      .then(() => {
        setSuccess(true)
        setLoading(false)
        // send reset password
      })
      .catch((err) => {
        console.error(err)
        setError(true)
        setLoading(false)
        // setSuccess(true)
      })
  }, [])

  // if (!loading && !error && !success) return <h1>Handle Verify Email</h1>
  // if (loading) return <h1>Loading...</h1>
  // if (error) return <h1>Error</h1>
  // if (success) return <h1>Success</h1>
  return (
    <div>
      <section className="intro">
        <div className="container">
          {loading ? (
            <div className="text lg bold center">...Loading!!!</div>
          ) : (
            <div>
              <div className="row justify-content-center">
                {error && (
                  <div className="col-8 col-md-6 col-lg-5">
                    <img src={staticFile('expired-link.png')} />
                  </div>
                )}
                {success && (
                  <div className="col-6 col-md-4 col-lg-2">
                    <img src={staticFile('recovered-email.png')} />
                  </div>
                )}
              </div>
              <div className="text xl bold center mt-20">
                {error ? 'This link has expired' : success ? 'Email Successfully Verified' : 'Ooops'}
              </div>
            </div>
          )}
        </div>
      </section>
      {success && (
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="text md center">
                  Your account was successfully recovered. Check your inbox. You’ve received a password reset email.
                  Follow the instructions mentioned in that email to reset your password.
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  )
}
