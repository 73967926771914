import create from 'zustand'
import { EventEmitter } from 'events'
import { auth, firebase } from '../firebase'
import { StatusType } from '../@types/Subscription'

let firestoreStatusConnection: any
const eventFirestoreStatus = new EventEmitter()

type Providers =
  | 'password'
  | 'phone'
  | 'google.com'
  | 'facebook.com'
  | 'twitter.com'
  | 'github.com'
  | 'apple.com'
  | 'yahoo.com'
  | 'hotmail.com'
  | 'microsoft.com'
  | 'anonymous'

interface Subscription extends StatusType {
  id: string
}

export type Store = {
  email: string
  displayName?: string
  provider?: Providers
  UID: string
  subscriptions: Subscription[]
}

const initialState: Store = {
  email: '',
  displayName: undefined,
  provider: undefined,
  UID: '',
  subscriptions: [],
}

const useStore = create<Store>(() => ({ ...initialState }))

auth.onAuthStateChanged((user) => {
  console.log(user)
  if (user) {
    useStore.setState({
      email: user.email!,
      displayName: user.displayName || undefined,
      UID: user.uid,
      provider: user.providerData[0]?.providerId as Providers,
    })
    eventFirestoreStatus.emit('connect')
  } else {
    eventFirestoreStatus.emit('disconect')
    useStore.setState({
      ...initialState,
    })
  }
})

export const actions = {
  signOut() {
    return auth.signOut()
  },
}

export { useStore }

eventFirestoreStatus.on('connect', () => {
  if (auth.currentUser?.uid && !firestoreStatusConnection) {
    firestoreStatusConnection = firebase
      .firestore()
      .collection(`app/subscription_v1/status/${auth.currentUser.uid}/access`)
      .onSnapshot((snap) => {
        const subscriptions = snap.docs.map((el) => ({
          id: el.id,
          ...(el.data() as StatusType),
        }))
        useStore.setState({ subscriptions })
      })
  }
})
eventFirestoreStatus.on('disconect', () => {
  if (firestoreStatusConnection) {
    firestoreStatusConnection()
    firestoreStatusConnection = undefined
  }
})
