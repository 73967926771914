import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import { Footer, FooterMedia } from '../../components/footer'
import { staticFile } from '../../helpers'
import { actions as actionsLogin } from '../../components/login/store'
import { useStore as useUserStore } from '../../store/user'
import { useStore, action } from './store.main'
import cn from 'classnames'
import Manage from './manage'
import Cancel from './Cancel'
import Svg from '../../components/svg'
import Upgrade, { useUpgradeStore } from './Upgrade'

const Main: React.FC<RouteComponentProps> = () => {
  const user = useUserStore()
  const showUpgrade = useUpgradeStore((store) => store.show)
  const { position } = useStore()
  return (
    <>
      <div>
        {user.UID ? (
          user.subscriptions.length ? (
            <>
              <section
                className="pt-0"
                css={`
                  background: #ffd558;
                `}
              >
                <div
                  className="d-flex mb-30"
                  css={`
                    background: #ffd558;
                    & > div {
                      flex-grow: 1;
                      flex-shrink: 0;
                      height: 68px;
                      transition: all 0.2s;
                      cursor: pointer;
                      background: #ffe28a;
                      &.active {
                        background: #ffd558;
                        button {
                          font-weight: 500;
                        }
                      }
                      &.profile {
                        border-bottom-right-radius: 20px;
                      }
                      &.subscription {
                        border-bottom-left-radius: 20px;
                      }
                      button {
                        height: 100%;
                        background: transparent;
                        width: 100%;
                        @media (min-width: 1200px) {
                          width: 540px;
                        }
                      }
                    }
                  `}
                >
                  <div
                    onClick={action.setPosition.bind(null, 'profile')}
                    className={cn('profile text md', {
                      active: position === 'profile',
                    })}
                  >
                    <button className="d-block ml-auto">Profile</button>
                  </div>
                  <div
                    onClick={action.setPosition.bind(null, 'subscription')}
                    className={cn('subscription text md', {
                      active: position === 'subscription',
                    })}
                  >
                    <button className="d-block mr-auto">Subscription</button>
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-8">
                      <div className="">
                        <div className="text lg center">Your Subscription</div>
                        <div className="text md center mt-20">
                          See the history of your subscriptions and used promo codes and manage them with ease
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container mb-90">
                  <Manage />
                </div>
              </section>
            </>
          ) : (
            <>
              <section
                className="pt-0"
                css={`
                  background: #ffd558;
                `}
              >
                <div
                  className="d-flex mb-30"
                  css={`
                    background: #ffd558;
                    & > div {
                      flex-grow: 1;
                      flex-shrink: 0;
                      height: 68px;
                      transition: all 0.2s;
                      cursor: pointer;
                      background: #ffe28a;
                      &.active {
                        background: #ffd558;
                        button {
                          font-weight: 500;
                        }
                      }
                      &.profile {
                        border-bottom-right-radius: 20px;
                      }
                      &.subscription {
                        border-bottom-left-radius: 20px;
                      }
                      button {
                        height: 100%;
                        background: transparent;
                        width: 100%;
                        @media (min-width: 1200px) {
                          width: 540px;
                        }
                      }
                    }
                  `}
                >
                  <div
                    onClick={action.setPosition.bind(null, 'profile')}
                    className={cn('profile text md', {
                      active: position === 'profile',
                    })}
                  >
                    <button className="d-block ml-auto">Profile</button>
                  </div>
                  <div
                    onClick={action.setPosition.bind(null, 'subscription')}
                    className={cn('subscription text md', {
                      active: position === 'subscription',
                    })}
                  >
                    <button className="d-block mr-auto">Subscription</button>
                  </div>
                </div>
                <div className="container">
                  <div style={{ width: '90%' }} className="mx-auto d-none d-sm-block mb-30">
                    <img src={staticFile('/manage_sub_head_2.png')} alt="" />
                  </div>
                  <div style={{ width: '75%' }} className="mx-auto d-block d-sm-none mb-30">
                    <img src={staticFile('/manage_sub_head_2small.png')} alt="" />
                  </div>
                  <div className="text lg center">You haven't subscribed yet!</div>
                  <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-20">
                      <Link
                        to="/premium"
                        className="text md center"
                        css={`
                          padding: 15px;
                          width: 100%;
                          color: white;
                          background: #3d85ea;
                          border-radius: 1000px;
                          transition: all 0.2s;
                          &:hover,
                          &.hover {
                            background-color: #8bbbff;
                          }
                        `}
                      >
                        SUBSCRIBE NOW
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
              <section
                css={`
                  svg {
                    width: 72px;
                  }
                `}
              >
                <div className="container">
                  <div className="text lg center">Why FunEasyLearn Premium?</div>
                  <div className="row mt-30">
                    <div className="col-sm-6 col-lg-4 d-flex flex-column align-items-center mb-30">
                      <Svg
                        css={`
                          path:nth-child(1) {
                            fill: #d7ced8;
                          }
                        `}
                        path={'/all_levels.svg'}
                      />
                      <div className="text md medium center my-10">All levels</div>
                      <div className="text sm center">Learn 6,000 words & 5,000 phrases</div>
                    </div>
                    <div className="col-sm-6 col-lg-4 d-flex flex-column align-items-center mb-30">
                      <Svg path={'/learn_fun_and_easy_ico.svg'} />
                      <div className="text md medium center my-10">All features</div>
                      <div className="text sm center">Learn fast & effectively</div>
                    </div>
                    <div className="col-sm-6 col-lg-4 d-flex flex-column align-items-center mb-30">
                      <Svg path={'/no___ads.svg'} />
                      <div className="text md medium center my-10">No ads</div>
                      <div className="text sm center">Learn without being interrupted</div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
        ) : (
          <>
            <div
              className="section pt-30 pb-60"
              css={`
                background: #ffd558;
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 3rem), 0 100%);
              `}
            >
              <div className="container">
                <img className="mx-auto d-block" src={staticFile('/redeem_code_head_1.png')} alt="" />
                <h2 className="text xl center mt-20">Manage your account</h2>
              </div>
            </div>
            <div className="section my-30">
              <div className="container">
                <div className="row justify-content-center align-items-center flex-column">
                  <div className="col-12 col-lg-10">
                    <div className="text lg center normal">
                      Sign in to your FunEasyLearn account to see your profile and manage your subscriptions
                    </div>
                  </div>
                  <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-30">
                    <button
                      onClick={actionsLogin.setShowLogin.bind(null, true)}
                      className="text md"
                      css={`
                        padding: 15px;
                        width: 100%;
                        color: white;
                        background: #3d85ea;
                        border-radius: 1000px;
                        transition: all 0.2s;
                        &:hover,
                        &.hover {
                          background-color: #8bbbff;
                        }
                      `}
                    >
                      SIGN IN
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Remark />
          </>
        )}
        <FooterMedia />
        <Footer />
      </div>
      <Cancel />
      {showUpgrade && <Upgrade />}
    </>
  )
}

const Remark = () => {
  return (
    <div
      className="section py-30"
      css={`
        background: #ffd558;
      `}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-10">
            <div className="d-flex">
              <div
                className="d-flex justify-content-center aling-items-center"
                css={`
                  width: 4rem;
                  height: 4rem;
                  flex-shrink: 0;
                  background: white;
                  border-radius: 50%;
                `}
              >
                <img src={staticFile('/alert.svg')} alt="" />
              </div>
              <div className="text md ml-20">
                Make sure you sign in to the same account you have used to sign in to the app.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
