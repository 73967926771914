import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import { HeadSuccess } from './Header'
import Card from '../../components/card'
import { staticFile } from '../../helpers'
import style from './style.module.scss'
import ActivePromocode from './ActivePromocode'
import { codeToName } from './helpers'
import { useRedeemState } from './redeem-state'

const Success = () => {
  const state = useRedeemState()
  return (
    <>
      <HeadSuccess />
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-md-8">
              <Card className="py-20">
                <div className="text md center">
                  <div>
                    Language: <span className="text medium">{codeToName(state.selected.course_id)}</span>
                  </div>
                  <div>
                    Plan: <span className="text medium">Lifetime</span>
                  </div>
                  <div>
                    Activation date: <span className="text medium">{dayjs(Date.now()).format('MMMM D, YYYY')}</span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className={['text lg bold center mt-30'].join(' ')}>What do you do now?</div>
          <div className={['row flex-column align-items-center', style.whatDoYouDo].join(' ')}>
            <div className="col-lg-10 col-xl-8 mt-15">
              <div className={[style.list, 'd-flex'].join(' ')}>
                <div className={style.number}>1</div>
                <div className="text md ml-15">Download the app:</div>
              </div>
            </div>
            <div className="col mt-15">
              <div className="row justify-content-center">
                <div className="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3 mb-20 m-md-0">
                  <a
                    href="https://fel.page.link/ios"
                    className={[style.download, style.download__apple, 'd-flex'].join(' ')}
                  >
                    <img src={staticFile('app_store_logo.svg')} alt="app store" />
                    <div className={[style.text, 'd-flex flex-column justify-content-center'].join(' ')}>
                      <div>Donwload on the</div>
                      <div>App Store</div>
                    </div>
                  </a>
                </div>
                <div className="col-11 col-sm-7 col-md-5 col-lg-4 col-xl-3">
                  <a
                    href="https://fel.page.link/android"
                    className={[style.download, style.download__android, 'd-flex'].join(' ')}
                  >
                    <img src={staticFile('google_store_logo.svg')} alt="google store" />
                    <div className={[style.text, 'd-flex flex-column justify-content-center'].join(' ')}>
                      <div>GET IT ON</div>
                      <div>Google Play</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-xl-8 mt-15">
              <div className={[style.list, 'd-flex'].join(' ')}>
                <div className={style.number}>2</div>
                <div className="text md ml-15">Sign in with this account to your app;</div>
              </div>
            </div>
            <div className="col-lg-10 col-xl-8 mt-15">
              <div className={[style.list, 'd-flex'].join(' ')}>
                <div className={style.number}>3</div>
                <div className="text md ml-15">
                  {state.selected.course_id === 0
                    ? 'Enjoy the FunEasyLearning journey.'
                    : 'Choose as your course language, the language you have just bought;'}
                </div>
              </div>
            </div>
            {state.selected.course_id !== 0 ? (
              <div className="col-lg-10 col-xl-8 mt-15">
                <div className={[style.list, 'd-flex'].join(' ')}>
                  <div className={style.number}>4</div>
                  <div className="text md ml-15">Enjoy the FunEasyLearning journey.</div>
                </div>
              </div>
            ) : (
              ''
            )}
            {state.selected.course_id !== 0 ? (
              <div className="col-lg-10 col-xl-8 mt-15">
                <div className={[style.list].join(' ')}>
                  <div className="text md ml-55">
                    <b>Note:</b> The Lifetime Subscription and all the unlocked contents and features it carries will
                    apply only to the bought language course.
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: '#F0EBF1' }}>
        <div className="container">
          <h3 className="text lg medium center">Get closer to your goal</h3>
          <div className={['row flex-column align-items-center mt-20', style.getCloserToYourGoal].join(' ')}>
            <div className="col-md-10 col-xl-8">
              <div className="text md center">
                Learn languages, memorize vocabulary units, complete levels and develop speaking the fun & easy way.
              </div>
            </div>
            <div className={['col-md-11 col-lg-10 col-xl-8 mt-30 d-flex', style.list].join(' ')}>
              <img src={staticFile('icon-brain.svg')} alt="brain" />
              <div className={[style.text, 'ml-15'].join(' ')}>
                <div className="text md bold mb-10">Powerful Vocabulary Builder</div>
                <div className="text sm">
                  Build an impressive vocabulary consisting of 6,000 words by playing 30 fun and effective games.
                </div>
              </div>
            </div>
            <div className={['col-md-11 col-lg-10 col-xl-8 mt-30 d-flex', style.list].join(' ')}>
              <img src={staticFile('icon-circle.svg')} />
              <div className={[style.text, 'ml-15'].join(' ')}>
                <div className="text md bold mb-10">Useful Phrasebook</div>
                <div className="text sm">
                  Take part in any business, travelling or daily conversations with the natives by speaking 5,000
                  phrases, sentences and expressions.
                </div>
              </div>
            </div>
            <div className={['col-md-11 col-lg-10 col-xl-8 mt-30 d-flex', style.list].join(' ')}>
              <img src={staticFile('icon-dart.svg')} alt="dart" />
              <div className={[style.text, 'ml-15'].join(' ')}>
                <div className="text md bold mb-10">Amazing Features</div>
                <div className="text sm">
                  Use of all of our learning tools for your advantage – Search, Review, Learn Just to Speak, Hide Word
                  or Sentence, and all the other features we are going to launch, after your purchase.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ActivePromocode />
    </>
  )
}

export default Success
