import React from 'react'
import style from './login.module.scss'

const Footer = () => {
  return (
    <div className={`${style.login__footer} mt-30`}>
      <div className="text xs center">
        <span>By signing in, you agree to our&nbsp;</span>
        <a className="text bold link" href="https://www.funeasylearn.com/terms" target="_blank">
          Terms
        </a>
        <span>&nbsp;and&nbsp;</span>
        <a className="text bold link" href="https://www.funeasylearn.com/privacy" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default Footer
