import React from 'react'
import { useToggle } from '../../hooks'
import { Head } from './Header'
import style from './style.module.scss'
import { redeemActions, useRedeemState } from './redeem-state'
import Card from '../../components/card'
import Colors from './colors.json'
import { staticFile } from '../../helpers'
import { codeToName } from './helpers'
import Loading from '../../components/loading'
import ActivePromocode from './ActivePromocode'
import { felService } from '../../services'
const color: any = Colors

const SelectCourse = () => {
  const [loading, toggleLoading] = useToggle(false)
  const store = useRedeemState()
  const courses = [
    3,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    80,
    20,
    21,
    24,
    25,
    28,
    29,
    30,
    32,
    34,
    35,
    37,
    44,
    46,
    65,
    47,
    48,
    49,
    51,
    53,
    55,
    58,
    59,
    60,
  ]
  const onClick = async (course: number) => {
    toggleLoading()
    redeemActions.setSelected(course, 0)
    felService.selectedCourseForPromocode(store.promocode, course).then(() => {
      toggleLoading()
      redeemActions.setPosition('finish')
    })
  }
  return (
    <div className={style.selectCourse}>
      <Head />
      <section>
        <div className="container">
          <h3 className="text lg medium center">Congratulations!</h3>
          <div className="text md center mt-15">
            You have activated your promo code.
            <br />
            Now choose the language you want to learn.
          </div>
          <div className="row justify-content-center justify-content-md-start">
            {courses.map((el) => (
              <div key={el} className="col-12 col-sm-6 col-md-4 col-lg-3">
                <Card className={`${style.card} mt-20 pb-10`}>
                  <div className={style.img}>
                    <div className={style.img__top} style={{ backgroundColor: color[el].top }} />
                    <div className={style.img__bottom} style={{ backgroundColor: color[el].bottom }} />
                    <img src={staticFile(`courses/${el}.png`)} alt={codeToName(el)} />
                  </div>
                  <div className="text sm center medium my-10">{codeToName(el)}</div>
                  <div className={style.button}>
                    <button
                      onClick={onClick.bind(null, el)}
                      className={['text sm', el === store.selected.course_id && loading && style.hover].join(' ')}
                    >
                      Choose
                    </button>
                    {el === store.selected.course_id && loading ? <Loading size="80%" /> : ''}
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <ActivePromocode />
    </div>
  )
}

export default SelectCourse
