import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { staticFile } from '../../helpers'
import { useTranslation } from '../../hooks/useTranslationV1'
import { auth } from '../../firebase'

export const HandleVerifyEmail: React.FC<{ actionCode: string; lang_id: string }> = ({ actionCode, lang_id }) => {
  const t = useTranslation(lang_id as '16')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    auth
      .checkActionCode(actionCode)
      .then((info) => {
        console.log(info)
        axios.post('https://us-central1-phrases-android.cloudfunctions.net/WELCOME_A_NEW_USER', {
          email: info.data.email,
        })
        return auth.applyActionCode(actionCode)
      })
      .then(() => {
        setSuccess(true)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
        setLoading(false)
        // setSuccess(true)
      })
  }, [])

  // if (!loading && !error && !success) return <h1>Handle Verify Email</h1>
  // if (loading) return <h1>Loading...</h1>
  // if (error) return <h1>Error</h1>
  // if (success) return <h1>Success</h1>
  return (
    <div>
      <section className="intro">
        <div className="container">
          {loading ? (
            <div className="text lg bold center">...Loading!!!</div>
          ) : (
            <div>
              <div className="row justify-content-center">
                <div className="col-8 col-md-6 col-lg-5">
                  <img src={staticFile(error ? 'expired-link.png' : 'verify-email.png')} />
                </div>
              </div>
              <div className="text xl bold center mt-20">
                {error ? t.this_link_has_expired : success ? t.email_successfully_verified : 'Ooops'}
              </div>
            </div>
          )}
        </div>
      </section>
      {success && (
        <section>
          <div className="container">
            <div className="text md center">
              {t.your_email_address_was_successfully_verified}
              <br />
              {t.please_open_the_app_and_sign_in_to_your_funeasylearn_account}
            </div>
          </div>
        </section>
      )}
    </div>
  )
}
