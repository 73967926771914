import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { felService } from '../../services'
import { useStore as userStore } from '../../store/user'
import { useLoadScript } from '../../hooks'
import { paddle } from '../../payment'

const Premium: React.FC<RouteComponentProps> = () => {
  const [courseId, setCourseId] = useState(16)
  const { provider, email, UID } = userStore()
  const handleClick = async () => {
    // @ts-ignore
    const link = await felService.getPaddleOverrideLink('test_one_day')
    await paddle.checkout(link, email, {
      UID,
      courseId,
    })
    console.log('ok!')
    alert('Success')
  }
  return (
    <div>
      <h1>Course Id</h1>
      <input type="text" placeholder="16" value={courseId} onChange={(e) => setCourseId(Number(e.target.value))} />
      <button
        className="text lg medium"
        css={`
          &,
          &:hover,
          &:active {
            display: block;
            background: red;
            padding: 20px;
          }
        `}
        onClick={handleClick}
      >
        Buy
      </button>
    </div>
  )
}

export default Premium
