import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { darken, lighten, position, size } from 'polished'
import Card from '../card'
import Button from '../button'
import { Products } from '../../@types/Products'
import { useWindowSize } from 'react-use'
import { breakpoints } from '../../styles/vars'

console.log(darken(0.2, '#EA6831'))

interface Props extends FirestoreData.Offer.ProductOffer {
  content: FirestoreData.Offer.ProductContent
  price: number
  oneMonthPrice: number
  productId: '1month' | '6month' | '12month' | 'lifetime'
  imgSrc: string
  onClick: () => void
}

function textPeriod(period: number): string {
  switch (period) {
    case 0:
      return 'Lifetime'
    case 1:
      return '1 month'
    default:
      return `${period} months`
  }
}

function textPricePerMonth(price: number, period: number, oneTime: boolean): number {
  if (period === 0 || period === 1 || oneTime) return price
  return Number((Math.round((price / period) * 10) / 10 - 0.01).toFixed(2))
}

function textDescription(
  month: number,
  price: number,
  oneTime: boolean,
  trial: number,
  discount: number,
  perMonth: number,
) {
  const oneTimeText = discount
    ? `<span style="color: #b00000; text-decoration: line-through;">$${price}</span> $${toFixed(
        discountFn(price, discount),
      )} due today`
    : 'one-time payment'
  if (oneTime) return oneTimeText
  // const fn = ()
  switch (month) {
    case 0:
      return oneTimeText
    case 1:
      return trial ? `$${price} charged every month after end of ${trial}-day trial` : discount ? oneTimeText : ``
    case 6:
      return trial
        ? `$${price} charged every 6 months after end of ${trial}-day trial`
        : discount
        ? oneTimeText
        : `<span style="color: #b00000; text-decoration: line-through;">$${toFixed(perMonth * 6)}</span> $${price}`
    case 12:
      return trial
        ? `$${price} charged every year after end of ${trial}-day trial`
        : discount
        ? oneTimeText
        : `<span style="color: #b00000; text-decoration: line-through;">$${toFixed(perMonth * 12)}</span> $${price}`
    default:
      throw new Error(`${month} undefined`)
  }
}

function getPeriod(productId: Props['productId']) {
  if (productId.includes('lifetime')) return 0
  const match = productId.match(/[0-9]+/)
  if (match) return Number(match[0])
  else throw new Error(`Invalid productId ${productId}`)
}

const PurhcaseCard: React.FC<Props> = ({ imgSrc, onClick, productId, price, ...rest }) => {
  const { width, height } = useWindowSize()
  const period = getPeriod(productId)
  const oneTime = productId === 'lifetime'
  if (width < breakpoints.md) {
    return (
      <div style={{ height: '100%' }} {...rest} onClick={onClick}>
        <Card
          css={`
            border: solid 2px ${rest.line_color};
          `}
        >
          <div
            css={`
              width: 100%;
              padding: 0 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div>
              <div>
                <div className="text md bold" style={{ fontSize: '24px' }}>
                  {textPeriod(period)}
                </div>
              </div>
              <div>
                <div className="text md">
                  <span>$</span>
                  <span>
                    {textPricePerMonth(
                      rest.discount ? toFixed(discountFn(price, rest.discount)) : price,
                      period,
                      oneTime,
                    )}
                  </span>
                  {oneTime ? null : <span>/m</span>}
                </div>
              </div>
            </div>
            <div>
              <Button
                css={`
                  padding-left: 20px;
                  padding-right: 20px;
                  background: ${rest.buy_background_color}!important;
                  color: ${rest.buy_text_color}!important;
                  :hover {
                    background: ${lighten(0.4, rest.buy_background_color)}!important;
                  }
                `}
              >
                {rest.trial ? rest.content.trial_button : rest.content.buy_button}
              </Button>
            </div>
          </div>

          <div style={{ width: '100%', marginLeft: '10px' }}>
            <div
              className="description text sm"
              dangerouslySetInnerHTML={{
                __html: textDescription(period, price, oneTime, rest.trial, rest.discount, rest.oneMonthPrice),
              }}
            />
          </div>
          {rest.save_percentage ? (
            <div
              className="text sm bold"
              css={`
                ${position('absolute', '-3rem', '1.2rem', null, null)}
                padding: 7px 14px;
                color: ${rest.save_text_color};
                background: ${rest.save_background_color};
                &::after {
                  ${position('absolute', null, '0', '0', null)}
                  ${size('1.5rem')}
              background: ${darken(0.2, rest.save_background_color)};
                  transform: translateY(100%);
                  clip-path: polygon(100% 0, 0% 100%, 0 0);
                  display: block;
                  content: '';
                }
              `}
            >
              Save {rest.save_percentage}%
            </div>
          ) : null}
        </Card>
      </div>
    )
  }
  return (
    <div style={{ height: '100%' }} {...rest} onClick={onClick}>
      <Card
        css={`
          border: solid 2px ${rest.line_color};
        `}
      >
        <div className="item">
          <div className="text md center">{textPeriod(period)}</div>
        </div>
        <div className="item">
          <img src={imgSrc} alt="" />
        </div>
        <div className="item">
          <div className="price">
            <span>$</span>
            <span>
              {textPricePerMonth(rest.discount ? toFixed(discountFn(price, rest.discount)) : price, period, oneTime)}
            </span>
            {oneTime ? null : <span>/m</span>}
          </div>
        </div>
        <div className="item">
          <Button
            css={`
              padding-left: 20px;
              padding-right: 20px;
              background: ${rest.buy_background_color}!important;
              color: ${rest.buy_text_color}!important;
              :hover {
                background: ${lighten(0.4, rest.buy_background_color)}!important;
              }
            `}
          >
            {rest.trial ? rest.content.trial_button : rest.content.buy_button}
          </Button>
        </div>
        <div className="item">
          <div
            className="description text sm center"
            dangerouslySetInnerHTML={{
              __html: textDescription(period, price, oneTime, rest.trial, rest.discount, rest.oneMonthPrice),
            }}
          />
        </div>
        {rest.save_percentage ? (
          <div
            className="text sm bold"
            css={`
              ${position('absolute', '-3rem', '1.2rem', null, null)}
              padding: 7px 14px;
              color: ${rest.save_text_color};
              background: ${rest.save_background_color};
              &::after {
                ${position('absolute', null, '0', '0', null)}
                ${size('1.5rem')}
                background: ${darken(0.2, rest.save_background_color)};
                transform: translateY(100%);
                clip-path: polygon(100% 0, 0% 100%, 0 0);
                display: block;
                content: '';
              }
            `}
          >
            Save {rest.save_percentage}%
          </div>
        ) : null}
      </Card>
    </div>
  )
}

export default styled(PurhcaseCard)`
  ${Card} {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 5px 10px;
    transition: all 0.2s;
    &:hover {
      transform: translateY(-0.7rem) scale(1.02);
      box-shadow: 0 4px 18px rgba(0, 0, 0, 0.284583);
    }
    .item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    img {
      width: 96px;
    }
    .price {
      display: flex;
      span {
        font-size: 18px;
      }
      span:nth-child(1) {
        font-weight: 500;
        margin-top: 0.5rem;
      }
      span:nth-child(2) {
        font-size: 54px;
        line-height: 60px;
        font-weight: 700;
      }
      span:nth-child(3) {
        align-self: flex-end;
        font-weight: 400;
        margin-bottom: 0.6rem;
      }
    }
    .description {
      font-size: 18px;
    }
  }
`

function toFixed(nr: number) {
  return Math.floor(nr * 100) / 100
}

function discountFn(price: number, discount: number) {
  return price * (1 - discount / 100)
}
