import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import firebaseConfig from './config'

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
export { auth, firebase }
