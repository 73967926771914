import { useState, useLayoutEffect } from 'react'

export function useMedia() {
  const [state, setState] = useState(document.body.clientWidth)
  useLayoutEffect(() => {
    const onWidthChange = () => {
      setState(window.innerWidth)
      // console.log())
    }
    window.addEventListener('resize', onWidthChange)
    return () => {
      window.removeEventListener('resize', onWidthChange)
    }
  }, [])
  return state
}
