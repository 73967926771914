import create from 'zustand'

type Position = 'sign-in' | 'reset-pass' | 'create-acc' | 'verification'

type State = {
  position: Position
  loading: boolean
  showLogin: boolean
  email: string
  password: string
  passwordError: string | JSX.Element
  emailError: string | JSX.Element
}

const initialState: State = {
  position: 'sign-in',
  loading: false,
  showLogin: false,
  email: '',
  password: '',
  passwordError: '',
  emailError: '',
}

const useStore = create<State>(() => ({ ...initialState }))

export const actions = {
  setLoading(loading: boolean) {
    useStore.setState({ loading })
  },
  setPosition(position: Position) {
    let password = useStore.getState().password
    if (position === 'create-acc') {
      password = ''
    }
    useStore.setState({
      position,
      password,
      passwordError: '',
      emailError: '',
      loading: false,
    })
  },
  setShowLogin(showLogin: boolean) {
    useStore.setState({ ...initialState, showLogin })
  },
  setEmail(email: string) {
    useStore.setState({ email })
  },
  setPassword(password: string) {
    useStore.setState({ password })
  },
  setPasswordError(passwordError?: string | JSX.Element) {
    useStore.setState({ passwordError: passwordError ? passwordError : '' })
  },
  setEmailError(emailError?: string | JSX.Element) {
    useStore.setState({ emailError: emailError ? emailError : '' })
  },
}

export { useStore }
