import axios from 'axios'
import { auth } from '../firebase'
import { Products } from '../@types/Products'
import { paddle } from '../payment'

class FELService {
  constructor(private SERVER_API_URI: string) {}

  getCoinbaseCharge = async (productId: keyof Products.Price, courseId: number) => {
    const token = await auth.currentUser?.getIdToken()
    const res = await this.fetch('/purchase/coinbase/create-charge', {
      method: 'POST',
      body: {
        productId,
        courseId,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return res.data.link
  }

  getPaddleOverrideLink = async (productId: keyof Products.Price, isTrial?: boolean): Promise<string> => {
    const res = await this.fetch('/override-price', {
      method: 'POST',
      body: {
        productId,
        trial: isTrial,
        affiliate: paddle.getAffiliateId(),
      },
    })
    return res.data.link
  }

  setRegistering = (email: string, password: string) => {
    return this.fetch('/registering', {
      method: 'PUT',
      body: {
        email,
        password,
      },
    })
  }

  confirmRegistering = (digit: number) => {
    return this.fetch('/registering', {
      method: 'POST',
      body: {
        digit,
      },
    })
  }

  resetPassword = async (email: string) => {
    return this.fetch('/reset-password', {
      method: 'POST',
      body: {
        email: email,
      },
    })
  }

  cancelSubscription = async (id: string) => {
    const token = await auth.currentUser?.getIdToken()
    return this.fetch('/subscription/cancel', {
      method: 'POST',
      body: {
        id,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  upgradeSubscription = async (id: string, productId: keyof Products.Price) => {
    const token = await auth.currentUser?.getIdToken()
    return this.fetch('/subscription/upgrade', {
      method: 'POST',
      body: {
        id,
        productId,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }

  private async fetch(
    url: string,
    options: {
      method: 'GET' | 'POST' | 'PUT'
      body?: any
      query?: any
      headers?: HeadersInit
    } = {
      method: 'GET',
    },
  ) {
    const { method, body, query, headers } = options
    if (method === 'GET' && body) throw new Error("Only applicable for request methods 'PUT', 'POST', and 'PATCH'")
    const res = await axios({
      url: this.SERVER_API_URI + url,
      method,
      data: body,
      params: query,
      headers,
    })
    if (res.status >= 200 && res.status < 300) return res.data
    else throw new Error(`${res.status}`)
  }

  selectedCourseForPromocode = async (promocode: string, course_id: number) => {
    const token = await auth.currentUser?.getIdToken()
    return this.fetch('/redeem/selected-course', {
      method: 'PUT',
      body: {
        promocode,
        course_id,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }
}

class FELServiceV2 {
  private axios
  constructor(private URL: string) {
    this.axios = axios.create({
      baseURL: this.URL,
    })
    console.log('123', this.URL)
  }

  getProducts(
    offer?: string,
  ): Promise<{
    offer: FirestoreData.Offer.OfferRoot
    productsLink: Record<string, string>
    prices: Products.Price
  }> {
    return this.axios
      .get('/purchase', {
        params: {
          offer,
        },
      })
      .then((a) => a.data)
  }
}

export const felService = new FELService(process.env.SERVER_API_URI)
export const felServiceV2 = new FELServiceV2(process.env.SERVER_API_URI_V2)

const customAxios = axios.create({
  baseURL: process.env.SERVER_API_URI,
})

interface Promocode {
  period: number
  end_date: number
  start_date: number
  course_id: number
  promocode: string
  document_id: string
}

export const fetcher = {
  async getProducts(url: string) {
    /**
     * @property {number} 1month
     * @property {number} 6month
     * @property {number} 12month
     * @property {number} lifetime
     */
    const data: Products.Price = await customAxios(url).then((res) => res.data)
    return data
  },
  async getAllPromocodes(url: string): Promise<Promocode[]> {
    const token = await auth.currentUser?.getIdToken()
    const { data } = await customAxios(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  // getPaddleOverrideLink = async (product: string): Promise<string> => {
  //   const res = await this.fetch('/override-price', {
  //     method: 'POST',
  //     body: {
  //       product,
  //     },
  //   })
  //   return res.data.link
  // },
}
