import { useEffect, useState } from 'react'
import { Products } from '../../../@types/Products'
import { felServiceV2 } from '../../../services'

const useProducts = (offer?: string) => {
  const [state, setState] = useState<{
    offer: FirestoreData.Offer.OfferRoot
    productsLink: Record<string, string>
    prices: Products.Price
  }>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    felServiceV2.getProducts(offer).then((data) => {
      setState(data)
      setLoading(false)
    })
  }, [])

  return <const>[state, loading]
}

export default useProducts
