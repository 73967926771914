import React from 'react'
import style from './style.module.scss'
import { staticFile } from '../../helpers'

export const Head = () => {
  return (
    <div className={style.intro}>
      <div className="container">
        <h1 className="text xl bold center mb-30">Redeem your code</h1>
        <img src={staticFile('redeem_code_head_1.png')} alt="bee with key" />
      </div>
    </div>
  )
}

export const HeadSuccess = () => {
  return (
    <div className={style.intro}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-5 col-md-4 mb-10 m-md-0">
            <img src={staticFile('redeem_code_all_set.png')} alt="bee" />
          </div>
          <div className="col-12 col-md">
            <h2 className="text xl bold center mb-30 mb-md-60">All set</h2>
            <h2 className="text lg medium center">Your FunEasyLearn Lifetime Membership was activated</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
