import React, { useState, useEffect } from 'react'
import { staticFile } from '../../helpers'
import { auth } from '../../firebase'
import { InputAuth } from '../../components/input'
import Button from '../../components/button'
import EmailHandler from './emailHandler'
import Card from '../../components/card'
import { padding } from 'polished'
import { countBy } from 'lodash'
import Loading from '../../components/loading'
import axios from 'axios'
import { useTranslation } from '../../hooks/useTranslationV1'

export const HandleResetPassword: React.FC<{ actionCode: string; lang_id: string }> = ({ actionCode, lang_id }) => {
  const t = useTranslation(lang_id as '16')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    setLoading(true)
    auth
      .verifyPasswordResetCode(actionCode)
      .then((_email) => {
        // setEmail(email)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(true)
        setLoading(false)
      })
  }, [])

  const handleNewPassword = (password: string) => {
    setLoading(true)
    auth
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        setLoading(false)
        setSuccess(true)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
        setError(true)
      })
  }
  const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    axios.post(`${process.env.CLOUD_FUNCTION_REGISTER_AUTH_ACTION!}/resetPassword`, {
      lang_id,
      email,
    })
  }

  // if (error) return <ExpiredLink />
  return (
    <div>
      <section className="intro">
        <div className="container">
          {loading ? (
            <div className="text lg bold center">...Loading!!!</div>
          ) : (
            <div>
              <div className="row justify-content-center">
                <div className="col-8 col-md-6 col-lg-5">
                  <img src={staticFile(error ? 'reset-password-expired-link.png' : 'reset-password.png')} />
                </div>
              </div>
              <div className="text xl bold center mt-20">
                {error ? t.this_link_has_expired : success ? t.password_successfully_reset : t.reset_password}
              </div>
            </div>
          )}
        </div>
      </section>
      {!loading && (
        <section>
          <div className="container">
            <div className="row flex-column align-items-center">
              <div className="col col-md-9 col-lg-8">
                <div className="text md center mb-30">
                  {error ? (
                    'Please enter your email address to request a new password reset link.'
                  ) : success ? (
                    <span>
                      {t.you_have_successfully_reset_your_password}
                      <br />
                      {t.please_sign_in_to_your_funeasylearn_account_with_your_new_password}
                    </span>
                  ) : (
                    t.please_fill_in_the_following_fields_to_reset_the_password_for_your_funeasylearn_account
                  )}
                </div>
                {error ? (
                  <form onSubmit={handleResetPassword}>
                    <div className="row flex-column align-items-center mt-30 mb-30">
                      <div className="col-11 col-md-9 col-lg-8">
                        <InputAuth type="email" placeholder="Email" value={email} onChange={setEmail} />
                      </div>
                      <Button
                        css={`
                          margin-top: 30px;
                          font-size: 24px;
                          ${padding(null, 20)}
                        `}
                      >
                        {t.send_link}
                      </Button>
                    </div>
                  </form>
                ) : success ? null : (
                  <ResetPassword cb={handleNewPassword} lang_id={lang_id} />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {/* <h1>{email}</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" onChange={(e) => setPassword(e.target.value)} />
        <input type="text" onChange={(e) => setConfirmPassword(e.target.value)} />
        <input type="submit" value="Submit" />
      </form> */}
    </div>
  )
}

const ResetPassword: React.FC<{ cb: (pass: string) => void; lang_id: string }> = ({ cb, lang_id }) => {
  const t = useTranslation(lang_id as '16')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorConfirm, setErrorConfirm] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password.length >= 6) {
      setErrorPassword('')
      if (password === confirmPassword) {
        setErrorConfirm('')
        cb(password)
      } else {
        setErrorConfirm(t.these_passwords_do_not_match)
      }
    } else {
      setErrorPassword(t.your_password_must_be_at_least_6_characters_long)
    }
  }

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <div className="row flex-column align-items-center mt-30 mb-30">
          <div className="col-11 col-md-9 col-lg-8">
            <InputAuth value={password} onChange={setPassword} type="password" placeholder={t.new_password} />
            {errorPassword && (
              <div
                className="text sm"
                css={`
                  margin-left: 65px;
                  color: #9a0000;
                `}
              >
                {errorPassword}
              </div>
            )}
          </div>
          <div className="col-11 col-md-9 col-lg-8 mt-10">
            <InputAuth
              value={confirmPassword}
              onChange={setConfirmPassword}
              type="password"
              placeholder={t.confirm_new_password}
            />
            {errorConfirm && (
              <div
                className="text sm"
                css={`
                  margin-left: 65px;
                  color: #9a0000;
                `}
              >
                {errorConfirm}
              </div>
            )}
          </div>

          <Button
            css={`
              margin-top: 20px;
              font-size: 24px;
              ${padding(null, 30)}
            `}
          >
            {t.reset_password}
          </Button>
        </div>
      </form>
    </Card>
  )
}
