import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useScrollFreeze } from '../../hooks'
import { staticFile } from '../../helpers'
import { useClickAway } from 'react-use'

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode
  onOpen?: () => void
  onClose?: () => void
}

const Popup: React.FC<Props> = ({ children, onClose, ...props }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  useScrollFreeze()
  useClickAway(contentRef, () => {
    if (onClose) onClose()
  })

  return (
    <div
      {...props}
      css={`
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.5rem;
        z-index: 1000;
        background: rgba(45, 26, 48, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        .content {
          padding: 1.5rem;
          border-radius: 4px;
          border: solid 1px #bdb0c0;
          background: white;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.142578);
          position: absolute;
          /* overflow-y: auto; */
        }
        .close {
          position: absolute;
          right: 0;
          bottom: calc(100% + 1rem);
          width: 2rem;
          height: 2rem;
          background-color: transparent;
          cursor: pointer;
        }
      `}
    >
      <div className="content" ref={contentRef}>
        {children}
        <div className="close" onClick={onClose}>
          <img src={staticFile('/close_popup.svg')} alt="close" />
        </div>
      </div>
    </div>
  )
}

export default Popup
