import React, { useState, useMemo } from 'react'
import style from './login.module.scss'
import Input from './input'
import { felService } from '../../services'
import { useStore, actions } from './store'

const Verification = () => {
  const store = useStore()
  const [digit, setDigit] = useState('')
  const [error, setError] = useState('')
  const [isFirstSend, setFirstSend] = useState(true)

  const resendCode = async () => {
    actions.setLoading(true)
    setDigit('')
    await felService.setRegistering(store.email, store.password)
    actions.setLoading(false)
    setFirstSend(false)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    actions.setLoading(true)
    if (isNaN(Number(digit))) {
      actions.setLoading(false)
      setError('Invalid verification code')
    } else {
      felService
        .confirmRegistering(Number(digit))
        .then(() => {
          actions.setPosition('sign-in')
        })
        .catch(() => {
          actions.setLoading(false)
          setError('Invalid or expired verification code')
        })
    }
  }

  return (
    <div>
      <div className="text lg medium center">Enter verification code</div>
      <div className="text sm center mb-30" style={{ color: isFirstSend ? 'black' : '#9a0000' }}>
        {isFirstSend ? (
          <>
            <span>
              We have sent you an email with a verification code to {store.email}. The code will be valid 10 minutes.
            </span>
            <span>Please enter your verification code to continue.</span>
          </>
        ) : (
          <>
            <span className="mb-10">We have resend you the verification code to {store.email}.</span>
            <span>
              Please add accounts@funeasylearn.com to your contacts, just to make sure you receive all the emails we
              send you.{' '}
            </span>
          </>
        )}
      </div>
      <form onSubmit={onSubmit} className={style.login__form}>
        <Input placeholder="6-digit code" type="digit" value={digit} onChange={setDigit} error={error} />

        <button className="text md">Continue</button>
      </form>
      <button onClick={resendCode} className="d-block mx-auto mt-30 text sm bold link">
        Resend code?
      </button>
    </div>
  )
}

export default Verification
