export const period = (period: number) => {
  if (period === 0) return 'Lifetime'
  else if (period === 1) return '1 Month'
  else if (typeof period === 'number') {
    return `${period} Months`
  } else {
    return 'Undefined'
  }
}

export const codeToName = (code: number) => {
  const map: Record<number, string> = {
    0: 'All Languages',
    1: 'Afrikaans',
    2: 'Albanian',
    66: 'Amharic',
    3: 'Arabic',
    4: 'Azerbaijani',
    7: 'Belarusian',
    6: 'Bengali',
    67: 'Bosnian',
    8: 'Bulgarian',
    9: 'Catalan',
    10: 'Chinese',
    11: 'Traditional Chinese',
    12: 'Croatian',
    13: 'Czech',
    14: 'Danish',
    15: 'Dutch',
    16: 'English',
    80: 'American English',
    18: 'Estonian',
    19: 'Tagalog',
    20: 'Finnish',
    21: 'French',
    23: 'Georgian',
    24: 'German',
    25: 'Greek',
    28: 'Hebrew',
    29: 'Hindi',
    30: 'Hungarian',
    31: 'Icelandic',
    70: 'Igbo',
    32: 'Indonesian',
    33: 'Gaelic',
    34: 'Italian',
    35: 'Japanese',
    71: 'Kazakh',
    37: 'Korean',
    39: 'Latvian',
    40: 'Lithuanian',
    41: 'Macedonian',
    42: 'Malay',
    69: 'Mongolian',
    72: 'Nepali',
    44: 'Norwegian',
    45: 'Farsi',
    46: 'Polish',
    65: 'Brazilian Portuguese',
    47: 'Portuguese',
    48: 'Romanian',
    49: 'Russian',
    50: 'Serbian',
    51: 'Slovak',
    52: 'Slovenian',
    53: 'Spanish',
    54: 'Swahili',
    55: 'Swedish',
    58: 'Thai',
    59: 'Turkish',
    60: 'Ukrainian',
    61: 'Urdu',
    62: 'Vietnamese',
    17: 'Esperanto',
    79: 'Zulu',
    74: 'Javanese',
    75: 'Khmer',
    93: 'Lao',
    77: 'Spanish (Mexico)',
    78: 'Yoruba',
  }
  if (map[code]) {
    return map[code]
  }
  return 'Undefined'
}
