import './style.scss'
import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
// import { useStore } from './purchase.state'
import style from './style.module.scss'
import styled, { css } from 'styled-components'
import Svg from '../../components/svg'
import { fetcher, felService } from '../../services'
import Carousel from './carousel'
import { Footer, FooterMedia } from '../../components/footer'
import { RouteComponentProps } from '@reach/router'
import { staticFile } from '../../helpers'
import Succes from './Succes'
import { useSelectStore, SelectCourse } from './SelectCourse'
import Loading from '../../components/loading'
import { useWindowLocation, useFirestoreDocumentData } from '../../hooks'
import { useStore as userStore } from '../../store/user'
import PurchaseCard from '../../components/purchase-card'
import { actions as loginActions } from '../../components/login/store'
import { paddle } from '../../payment'
import { Products } from '../../@types/Products'
import useProducts from './hooks/useProducts'
import HurryUp from './hurryUp'

const button = {
  oneOrAll: styled.button<{ active?: boolean }>`
    width: 100%;
    padding: 7px 0;
    border-radius: 10000px;
    background: transparent;
    color: #1476df;

    &,
    &:focus,
    &:active {
      border: 2px solid #1476df;
      box-sizing: border-box;
    }

    transition: all 0.2s;
    ${(prop) =>
      prop.active &&
      css`
        background-color: #1476df;
        color: white;
      `}
  `,
}

const Purchase: React.FC<RouteComponentProps> = () => {
  const location = useWindowLocation()
  const { UID, provider, email } = userStore()
  const [oneOrAll, setOneOrAll] = useState<'one' | 'all'>('one')
  const [selected, setSelected] = useState<{ courseId: number; month: number; trial: number } | undefined>()
  const { show: showSelectCourse, getCourse } = useSelectStore()
  const [type, setType] = useState<'normal' | 'trial' | 'crypto'>('normal')
  const [data, loading] = useProducts(location.query['offer'])
  useEffect(() => {
    if (data) {
      if (data.offer.details.group_all.visible && data.offer.details.group_one.visible) {
        setOneOrAll('one')
      } else if (data.offer.details.group_all.visible) {
        setOneOrAll('all')
      } else {
        setOneOrAll('one')
      }
      console.log(data)
    }
  }, [data])

  if (!data || loading) return <Loading src={staticFile('loading_v2.json')} />

  const handlePurhcase = async (product: FirestoreData.Offer.ProductOffer, productId: string) => {
    const month = productId.includes('lifetime')
      ? 0
      : productId.includes('1month')
      ? 1
      : productId.includes('6month')
      ? 6
      : 12
    console.log(data.productsLink[productId])
    if (provider && provider !== 'anonymous') {
      let courseId = 0
      if (oneOrAll === 'one') {
        try {
          courseId = await getCourse()
        } catch {
          return
        }
        await paddle.checkout(data.productsLink[productId], email, {
          UID,
          courseId,
        })
        setSelected({ courseId, month, trial: product.trial })
      } else {
        await paddle.checkout(data.productsLink[productId], email, {
          UID,
          courseId,
        })
        setSelected({ courseId, month, trial: product.trial })
      }
    } else {
      loginActions.setShowLogin(true)
      console.info('Log in or user is anonymous')
    }
  }
  const selectedOfferData = data.offer.details[oneOrAll === 'one' ? 'group_one' : 'group_all']
  if (selected) return <Succes {...selected} />
  if (showSelectCourse) return <SelectCourse />
  return (
    <div className={style.purchase}>
      <div className={style.intro} />
      <div className={style.products}>
        <div className="container">
          <h1 className="text xl bold center">{data.offer.details.content['16'].h1}</h1>
          {data.offer.details.content['16'].h2 && (
            <h1 className="text md center mt-10">{data.offer.details.content['16'].h2}</h1>
          )}
          <div className="row justify-content-center mt-30 flex-column flex-sm-row align-items-center align-items-sm-start">
            {data.offer.details.group_one.visible && (
              <div className="col-9 col-sm-6 col-md-5 col-lg-3 mb-20 mb-sm-0">
                <button.oneOrAll
                  active={oneOrAll === 'one'}
                  className="text sm medium"
                  onClick={setOneOrAll.bind(null, 'one')}
                >
                  One language
                </button.oneOrAll>
              </div>
            )}
            {data.offer.details.group_all.visible && (
              <div className="col-9 col-sm-6 col-md-5 col-lg-3">
                <button.oneOrAll
                  active={oneOrAll === 'all'}
                  className="text sm medium"
                  onClick={setOneOrAll.bind(null, 'all')}
                >
                  All languages (34)
                </button.oneOrAll>
              </div>
            )}
          </div>
          <div className={`row justify-content-center mt-55`}>
            {selectedOfferData['1month'].visible && (
              <div className={`col-12 col-md-6 col-lg-3 mb-40 order-${selectedOfferData['1month'].order}`}>
                <PurchaseCard
                  oneMonthPrice={data.prices[oneOrAll === 'all' ? 'all_1month' : 'one_1month']}
                  content={data.offer.details.content[16]}
                  productId="1month"
                  price={data.prices[oneOrAll === 'all' ? 'all_1month' : 'one_1month']}
                  imgSrc={staticFile('1_m.svg')}
                  {...selectedOfferData['1month']}
                  onClick={() =>
                    handlePurhcase(selectedOfferData['1month'], oneOrAll === 'all' ? 'all_1month' : 'one_1month')
                  }
                />
              </div>
            )}
            {selectedOfferData['6month'].visible && (
              <div className={`col-12 col-md-6 col-lg-3 mb-40 order-${selectedOfferData['6month'].order}`}>
                <PurchaseCard
                  oneMonthPrice={data.prices[oneOrAll === 'all' ? 'all_1month' : 'one_1month']}
                  content={data.offer.details.content[16]}
                  productId="6month"
                  price={data.prices[oneOrAll === 'all' ? 'all_6month' : 'one_6month']}
                  imgSrc={staticFile('6_m.svg')}
                  {...selectedOfferData['6month']}
                  onClick={() =>
                    handlePurhcase(selectedOfferData['6month'], oneOrAll === 'all' ? 'all_6month' : 'one_6month')
                  }
                />
              </div>
            )}
            {selectedOfferData['12month'].visible && (
              <div className={`col-12 col-md-6 col-lg-3 mb-40 order-${selectedOfferData['12month'].order}`}>
                <PurchaseCard
                  oneMonthPrice={data.prices[oneOrAll === 'all' ? 'all_1month' : 'one_1month']}
                  content={data.offer.details.content[16]}
                  price={data.prices[oneOrAll === 'all' ? 'all_12month' : 'one_12month']}
                  productId="12month"
                  imgSrc={staticFile('12_m.svg')}
                  {...selectedOfferData['12month']}
                  onClick={() =>
                    handlePurhcase(selectedOfferData['12month'], oneOrAll === 'all' ? 'all_12month' : 'one_12month')
                  }
                />
              </div>
            )}
            {selectedOfferData['lifetime'].visible && (
              <div className={`col-12 col-md-6 col-lg-3 mb-40 order-${selectedOfferData['lifetime'].order}`}>
                <PurchaseCard
                  oneMonthPrice={data.prices[oneOrAll === 'all' ? 'all_1month' : 'one_1month']}
                  content={data.offer.details.content[16]}
                  price={data.prices[oneOrAll === 'all' ? 'all_lifetime' : 'one_lifetime']}
                  productId="lifetime"
                  imgSrc={staticFile('forever.svg')}
                  {...selectedOfferData['lifetime']}
                  onClick={() =>
                    handlePurhcase(selectedOfferData['lifetime'], oneOrAll === 'all' ? 'all_lifetime' : 'one_lifetime')
                  }
                />
              </div>
            )}
          </div>
          <div className="text sm center mb-30">All prices include VAT</div>
          {data.offer.details.hurryup && <HurryUp />}
          <div className="my-30 text sm center max-width-75 mx-auto">
            <b>14-Day Money-Back Guarantee</b>
            <br />
            The 14-Day Money-Back Guarantee is our way of ensuring your complete satisfaction with our app. When you
            subscribe, you will receive a full working version of our app, not a time-limited trial. If you are not
            satisfied with the product, then let us know within 14 calendar days of purchase, and we will give you a
            full refund.
          </div>

          {/* {typeQuery !== 'crypto' && (
            <div className="text sm bold center">
              No Credit Card?{' '}
              <a href="/premium?type=crypto" className="link">
                Pay with Crypto
              </a>
            </div>
          )} */}
        </div>
      </div>
      <div className={`${style.section__2} py-60`}>
        <div className="container">
          <h2 className="text lg center mb-30">Why FunEasyLearn Premium?</h2>
          <div className={style.grid}>
            <div className={style.block}>
              <Svg path={'/all_levels.svg'} />
              <div className="text md medium center my-10">All levels</div>
              <div className="text sm center">Learn 6,000 words & 5,000 phrases</div>
            </div>
            <div className={style.block}>
              <Svg
                path={'/learn_fun_and_easy_ico.svg'}
                css={`
                  g > g > path:nth-child(1) {
                    fill: white;
                  }
                `}
              />
              <div className="text md medium center my-10">All features</div>
              <div className="text sm center">Learn fast & effectively</div>
            </div>
            <div className={style.block}>
              <Svg path={'/no___ads.svg'} />
              <div className="text md medium center my-10">No ads</div>
              <div className="text sm center">Learn without being interrupted</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section__3} py-30`}>
        <div className="container">
          <h2 className="text lg center mb-30">People like you love FunEasyLearn</h2>
          <Carousel />
        </div>
      </div>
      <div className={`${style.section__4} py-30`}>
        <div className="container">
          <img className="mt-30" src={staticFile('secure.svg')} alt="secure" />
          <div className="text center">
            <div className="text lg medium mb-10">Secure payment</div>
            <div className="row justify-content-center">
              <div className="col col-lg-6">
                <div className="text sm">
                  All the transactions will be processed by the Paddle platform over an SSL-encrypted connection.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMedia />
      <Footer />
    </div>
  )
}

export default Purchase
