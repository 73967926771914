import React, { memo } from 'react'
import create from 'zustand'
import { firebase } from '../../firebase'
import Popup from '../../components/popup'
import { ISubscription } from './type'
import { below } from '../../styles/media-query'
import dayjs from 'dayjs'
import { felService } from '../../services'
import { useToggle } from '../../hooks'
import { staticFile } from '../../helpers'
import Loading from '../../components/loading'
import { BUY_FROM } from '../../@types/Subscription'

const Cancel = () => {
  const [loading, _, setLoading] = useToggle(false)
  const show = useCancelPopup((store) => store.show)
  const subscription = useCancelPopup((store) => store.subscription!)
  const promiseResolve = useCancelPopup((store) => store.promiseResolve!)

  const onCancel = async () => {
    setLoading(true)
    try {
      if (subscription.access_type === 2) {
        await firebase.functions().httpsCallable('notificationClientFlower')({
          course_id: subscription.course_id,
          action: 'unsubscribe',
        })
        promiseResolve((res) => res())
      } else if ([BUY_FROM.paddle, BUY_FROM.google].includes(subscription.buy_from)) {
        const res = await felService.cancelSubscription(subscription.id)
        console.log(res)
      }
      // if android
      // after cancel remove popup
      useCancelPopup.setState({ show: false })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  if (!show) return null
  return (
    <Popup
      onClose={() => useCancelPopup.setState({ show: false })}
      css={`
        .content {
          max-width: 570px;
        }
      `}
    >
      {loading && <Loading src={staticFile('loading_v2.json')} />}
      <div className="text md medium center mb-10">{subscription.language}</div>
      <div className="text sm center">Activated: {subscription.activated}</div>
      <div className="text sm center">Expires on: {dayjs(subscription.end_date).format('DD.MM.YY')}</div>
      <div className="text md medium center my-10">Cancel your subscription?</div>
      <div className="text sm center">
        You will lose your access to the Premium levels of words & sentences and to all the Premium features. Are you
        sure you want to cancel your subscription? <br />
        <br /> Note: If you cancel your Premium subscription now, it will still be active until the end of the
        subscription period.
      </div>
      <div
        className="mt-30"
        css={`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 30px;
          ${below.sm} {
            grid-template-columns: 1fr;
          }
          button {
            grid-column: span 1;
            padding: 15px;
            width: 100%;
            color: white;
            background: #3d85ea;
            border-radius: 1000px;
            transition: all 0.2s;
            ${below.sm} {
              margin: 0 auto;
              width: 90%;
              &:first-child {
                margin-bottom: 20px;
              }
            }
            &:hover,
            &.hover {
              background-color: #8bbbff;
            }
          }
        `}
      >
        <button onClick={onCancel} className="text md medium">
          Yes
        </button>
        <button onClick={() => useCancelPopup.setState({ show: false })} className="text md medium">
          No
        </button>
      </div>
    </Popup>
  )
}

export default memo(Cancel)

type CancelStore = {
  show: boolean
  promiseResolve?: (cb: (res: () => void, rej: (reason?: any) => void) => void) => void
  subscription?: ISubscription
  // subscription: any
  cancelSubscription: (subscription: ISubscription) => Promise<void>
}

export const useCancelPopup = create<CancelStore>((set, get) => ({
  show: false,
  cancelSubscription: (subscription: ISubscription) => {
    set({ show: true, subscription })
    const { promiseResolve } = get()
    if (promiseResolve) {
      promiseResolve((_, rej) => rej())
    }
    return new Promise((res, rej) => {
      set({
        promiseResolve: (cb) => {
          set({
            promiseResolve: undefined,
          })
          cb(res, rej)
        },
      })
    })
  },
}))
