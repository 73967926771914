import React from 'react'
import axios from 'axios'
import Lottie from 'react-lottie'
import styles from './login.module.scss'
import _ from 'lodash'

class Loading extends React.PureComponent {
  private static loading: {}

  isAlive: boolean = false

  componentDidMount() {
    this.isAlive = true
    if (_.isEmpty(Loading.loading)) {
      this.fetch()
    }
  }

  componentWillUnmount() {
    this.isAlive = false
  }

  fetch() {
    axios
      .get(`${process.env.STATIC_FILE_URI}/loading.json`)
      .then((data) => {
        Loading.loading = data.data
        console.log(data.data)
        if (this.isAlive) {
          this.forceUpdate()
        }
      })
      .catch()
  }

  render() {
    const options = {
      loop: true,
      animationData: Loading.loading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
      <div className={styles.loading}>
        {!_.isEmpty(Loading.loading) ? <Lottie options={options} height={200} width={200} /> : 'Loading...'}
      </div>
    )
  }
}

export default Loading
