import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useWindowLocation } from '../../hooks'
import { auth } from '../../firebase'
import { HandleVerifyEmail } from './handleVerifyEmail'
import { HandleResetPassword } from './handleResetPassword'
import { Footer, FooterMedia } from '../../components/footer'
import { HandleRecoverEmail } from './handleRecoverEmail'

const EmailHandler: React.FC<RouteComponentProps> = () => {
  const URL = useWindowLocation()
  const actionCode = URL.query.oobCode as string
  const lang_id = URL.query.lang as string
  console.log(actionCode)
  // auth.languageCode = 'fr'
  // const app = firebase.initializeApp(/* { apiKey: URL.query.apiKey } */)
  // const auth = app.auth()

  return (
    <div>
      {(() => {
        switch (URL.query.mode) {
          case 'resetPassword':
            return <HandleResetPassword actionCode={actionCode} lang_id={lang_id} />
          case 'recoverEmail':
            return <HandleRecoverEmail actionCode={actionCode} />
          case 'verifyEmail':
            return <HandleVerifyEmail actionCode={actionCode} lang_id={lang_id} />
          default:
            return <div>OOOps</div>
        }
      })()}
      <div style={{ marginTop: '120px' }}>
        <FooterMedia />
        <Footer />
      </div>
    </div>
  )
}

export default EmailHandler
